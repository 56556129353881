import { TYPE } from '../actions/types'

export default function (state = {}, action) {
    switch (action.type) {
        case TYPE.GET_ESCALATION_DETAILS:
            return { ...state, response: action.payload }
        case TYPE.INSERT_ESCALATION_DETAILS:
            return { ...state, response: action.payload }
        default:
            return state;
    }
}

