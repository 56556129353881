import { TYPE } from "../actions/types";
const initialState = {
    bookingsData: [],
    loading: true,
    couponData: {},
    bookingDetailsObj: {},
    isLoadingBookingDetails: false,
    createCouponRes: {},
    creditCashbackRes: {},
    cancelDayBookingRes: {},
    getHolidaBookingCommentRes: {},
    addHolidaBookingCommentRes: {},
    getHolidaReviewRes: {},
    setHolidaReviewRes: {}
};

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.GET_HOLIDA_ADMIN_BOOKINGS_BY_FILTERS:
            state.bookingsData = response
            state.loading = false
            state.isLoadingBookingDetails = false
            return {
                ...state,
                response: action.payload
            }
        case TYPE.GET_HOLIDA_ADMIN_BOOKING_ID_DETAILS:
            state.bookingDetailsObj = response
            state.isLoadingBookingDetails = false
            return {
                ...state,
                response: action.payload
            }
        case TYPE.CREATE_TICKET_RESOLUTION_COUPON:
            state.createCouponRes = response
            return {
                ...state,
                response: action.payload
            }
        case TYPE.HOLIDA_CREDIT_TRANSFER:
            state.creditCashbackRes = response
            return {
                ...state,
                response: action.payload
            }
        case TYPE.CANCEL_DAY_BOOKING:
            state.cancelDayBookingRes = response
            return {
                ...state,
                response: action.payload
            }
        case TYPE.GET_HOLIDA_BOOKING_COMMENTS:
            state.getHolidaBookingCommentRes = response;
            return { ...state, response: action.payload };
        
        case TYPE.ADD_HOLIDA_BOOKING_COMMENT:
            state.addHolidaBookingCommentRes = response;
            return { ...state, response: action.payload };
    
        case TYPE.GET_HOLIDA_REVIEW:
            state.getHolidaReviewRes = response;
            return { ...state, response: action.payload }

        case TYPE.POST_HOLIDA_REVIEW:
            state.setHolidaReviewRes = response;
            return { ...state, response: action.payload }

        case "clear_get_holida_review_res":
            state.getHolidaReviewRes = {};
            return { ...state, response: action.payload };
    
        case "clear_set_holida_review_res":
            state.setHolidaReviewRes = {};
            return { ...state, response: action.payload };

        case "clear_get_holida_comment_res":
            state.getHolidaBookingCommentRes = {};
            return { ...state, response: action.payload };
    
        case "clear_add_holida_comment_res":
            state.addHolidaBookingCommentRes = {};
            return { ...state, response: action.payload };

        case "clear_holida_bookings_list":
            state.bookingsData = []
            state.loading = true
        case "clear_create_couponRes":
            state.createCouponRes = {}
        case "clear_holida_credit_transfer_res":
            state.creditCashbackRes = {}
        case "clear_cancel_day_booking_res":
            state.cancelDayBookingRes = {}
        default:
            return state;
    }
}
