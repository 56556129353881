
import { TYPE } from '../actions/types'

export default function (state = {}, action) {
    switch (action.type) {
        case TYPE.GET_EXTRANET_USER_BY_FILTERS:
            return { ...state, response: action.payload }
        case TYPE.GET_USER_EXTRANET_PERMISSION_DETAILS:
            return { ...state, response: action.payload }
        case TYPE.UPDTAE_USER_EXTRANET_PERMISSIONS:
            return { ...state, response: action.payload }
        case TYPE.GET_USER_DETAILS:
            return { ...state, response: action.payload}
        case TYPE.UPDATE_USER_DETAILS:
            return { ...state, response: action.payload}
        case TYPE.CREATE_NEW_USER:
            return { ...state, response: action.payload}
        default:
            return state;
    }
}

