import { TYPE } from '../actions/types'

const intialState ={
    getAmenityRes:{},
    updateAmenityRes:{}
}

export default function (state = intialState, action,response=action.payload) {
    switch (action.type) {
        case TYPE.GET_PROPERTY_AMENITIES:
            state.getAmenityRes=response
            return { ...state, response: action.payload }
        case TYPE.ADD_REMOVE_PROPERTY_AMENITIES:
            state.updateAmenityRes=response
            return { ...state, response: action.payload }
        case "clear_update_amenity_api_res" :
            state.updateAmenityRes={}
            return { ...state, response: action.payload }
        default:
            return state;
    }
}

