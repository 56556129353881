import { TYPE } from "../actions/types";

const intialState = {
  getNearByHotelsRes: {},
  isLoading: false
};

export default function (state = intialState, action) {
  switch (action.type) {
    case TYPE.NEAREST_HOTEL:
      state.getNearByHotelsRes = action.payload;
      state.isLoading = false
      return {
        ...state,
        response: action.payload
      };
    case "clear_get_nearest_hotel_res":
      state.getNearByHotelsRes = {};
      state.isLoading = false
      return { ...state, response: action.payload };
    default:
      return state;
  }
}
