import { TYPE } from "../actions/types";

const initialState = {
  campaignsList: [],
  loading: false,
  editCampaign:{},
  createNewCampaignResponse:{},
  isCampaignUpdated:""
};

export default function (state = initialState,action,response = action.payload) {
  switch (action.type) {
    case TYPE.CAMPAIGNS_LIST.POST:
      state.campaignsList = response;
      return {
        ...state,
        loading: false,
      };
    case TYPE.EDIT_CAMPAIGN.POST:
      state.editCampaign = response
      
      response.status === "SUCCESS"
        ? (state.isCampaignUpdated = "SUCCESS")
        : (state.isCampaignUpdated = "ERROR");
     
      return {
        ...state,
        loading: false,
      };
    case TYPE.CREATE_CAMPAIGN.POST:
      state.createNewCampaignResponse = response
      return{
        ...state,
        loading: false
      }
    case "set_loading":
      state.loading = true;

      return {
        ...state,
      };
    case "clear_campaign_state":
      state.campaignsList = [];

      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

