import { TYPE } from "../actions/types";
import moment from "moment";
const initialState = {
  analyticsData: [],
  citydata:[],
  hoteldata:[],
  datehoteldata:[],
  cardData:[],
  datelist:[],
  datemonthlist:[],
  areadata:[],
  areahoteldata:[],
  areamonthdata:[],
  areahoteldatedata:[],
  balancedetails:[],
  paymentmodechange:[],
  monthdata:[],
  admindata:[],
  admincitydata:[],
  loading: false
};

export default function (state = initialState, action, response = action.payload) {
  switch (action.type) {
    case TYPE.ANALYTICS_DATA.POST:

       state.analyticsData = response;

        state.loading= false;
        if(state.analyticsData.msg === "Data fetched successfully" ){

          state.analyticsData.data.data.map((i)=>{
                i.check_in_date = i.check_in_date + ` (${moment(i.check_in_date).format('ddd')})`
            })
        }


      return {
        ...state,

      };
      case TYPE.ANALYTICS_DATA.POST+"citydata":

       state.citydata = response;

        state.loading= false;



      return {
        ...state,

      };

      case TYPE.ANALYTICS_DATA.POST+"admincitydata":

       state.admincitydata = response;

        state.loading= false;



      return {
        ...state,

      };
      case TYPE.ANALYTICS_DATA.POST+"admindata":

      state.admindata = response;

       state.loading= false;



     return {
       ...state,

     };


      case TYPE.ANALYTICS_DATA.POST+"hotel":

         state.hoteldata = response;

          state.loading= false;



        return {
          ...state,

        };
        case TYPE.ANALYTICS_DATA.POST+"datehotel":

          state.datehoteldata = response;


           state.loading= false;

           if(state.datehoteldata.msg === "Data fetched successfully" ){

            state.datehoteldata.data.data.map((i)=>{
                  i.check_in_date = i.check_in_date + ` (${moment(i.check_in_date).format('ddd')})`
              })
          }
                   return {
           ...state,

         };

         case TYPE.ANALYTICS_DATA.POST+"areahoteldata":

          state.areahoteldata = response;

           state.loading= false;
                   return {
           ...state,

         };
         case TYPE.ANALYTICS_DATA.POST+"areamonthdata":

         state.areamonthdata = response;

          state.loading= false;
                  return {
          ...state,

        };

         case TYPE.ANALYTICS_DATA.POST+"areadata":

          state.areadata = response;

           state.loading= false;

         return {
           ...state,

         };
         case TYPE.ANALYTICS_DATA.POST+"monthdata":

         state.monthdata = response;

          state.loading= false;

        return {
          ...state,

        };

         case TYPE.ANALYTICS_DATA.POST+"areahoteldatedata":

          state.areahoteldatedata = response;


           state.loading= false;

           if(state.areahoteldatedata.msg === "Data fetched successfully" ){

            state.areahoteldatedata.data.data.map((i)=>{
                  i.check_in_date = i.check_in_date + ` (${moment(i.check_in_date).format('ddd')})`
              })
          }
                   return {
           ...state,

         };

        case TYPE.ANALYTICS_DATA.POST+"datelist":

         state.datelist = response;

          state.loading= false;
          if(state.datelist.msg === "Data fetched successfully" ){

            state.datelist.data.data.map((i)=>{
                  i.check_in_date = i.check_in_date + ` (${moment(i.check_in_date).format('ddd')})`
              })
          }





        return {
          ...state,

        };

        case TYPE.ANALYTICS_DATA.POST+"datemonthlist":

        state.datemonthlist = response;

         state.loading= false;
         
       return {
         ...state,

       };


      case TYPE.ANALYTICS_DATA.GET:
        if(response.status === "SUCCESS"){

          state.cardData = response;
        }

        return {
          ...state,

        };
        case TYPE.ANALYTICS_DATA.POST+"balance_details":

          state.balancedetails = response;

           state.loading= false;

         return {
           ...state,

         };
      case "clear_analytics_data":
        state.analyticsData =[];

         state.loading = true;
      return {
        ...state,

      };
      case "clear_city_data":
        state.citydata =[];

         state.loading = true;
      return {
        ...state,

      };
      case "clear_admin_city_data":
        state.admincitydata =[];

         state.loading = true;
      return {
        ...state,

      };
      case "clear_admin_data":
        state.admindata =[];

         state.loading = true;
      return {
        ...state,

      };

      case "clear_area_data":
        state.areadata =[];

         state.loading = true;
      return {
        ...state,

      };
      case "clear_area_hotel_data":
        state.areahoteldata =[];

         state.loading = true;
      return {
        ...state,

      };
      case "clear_area_hotel_date_data":
        state.areahoteldatedata =[];

         state.loading = true;
      return {
        ...state,

      };

      case "clear_area_month_data":
        state.areamonthdata =[];

         state.loading = true;
      return {
        ...state,

      };
      case "clear_month_data":
        state.monthdata =[];

         state.loading = true;
      return {
        ...state,

      };


      case "clear_hotel_data":

      state.hoteldata=[];
      state.datehoteldata=[];

         state.loading = true;
      return {
        ...state,

      };


      case "clear_datelist_data":


      state.datelist=[];
         state.loading = true;
      return {
        ...state,

      };

      case "clear_datemonthlist_data":


      state.datemonthlist=[];
         state.loading = true;
      return {
        ...state,

      };

      case "clear_card_data":


        state.cardData=[];

        return {
          ...state,

        };
        case "clear_payment_change_mode":


          state.paymentmodechange=[];
  
          return {
            ...state,
  
          };

        case TYPE.ANALYTICS_DATA.PAYMENT_MODE_CHANGE:

          state.paymentmodechange = response;
   
         return {
           ...state,
   
         };

    default:
      return state;
  }
}
