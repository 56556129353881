import { getPaymentComment } from "../actions/profile_comment_action";
import { TYPE } from "../actions/types";

const initialState ={
  getCommentRes:{},
  getPaymentCommentRes:{}
}
export default function (state = initialState, action) {
  switch (action.type) {
    case TYPE.GET_GENERAL_COMMENT:
      state.getCommentRes=action.payload
      return { ...state, response: action.payload };
    case TYPE.GET_PAYMENT_COMMENT:
      state.getPaymentCommentRes=action.payload
      return { ...state, response: action.payload };
    case "clear_general_comment_res":
      state.getCommentRes={}
      return { ...state, response: action.payload };
    case "clear_payment_comment_res":
      state.getPaymentCommentRes={}
      return { ...state, response: action.payload };
    default:
      return state;
  }
}
