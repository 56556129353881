import { TYPE } from '../actions/types'

const initialState = {
    createUpdateCronDetails: {},
    isCreateUpdateCronDetails: false,
    getCronDetails: {},
    isGetCronDetails: false,
    getCronLogs: {},
    isGetCronLogs: false,


}

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.CREATE_UPDATE_CRON_DETAILS:
            state.createUpdateCronDetails = response
            state.isCreateUpdateCronDetails = false
            return { ...state, response: action.payload }
        case TYPE.GET_CRON_DETAILS:
            state.getCronDetails = response
            state.isGetCronDetails = false
            return { ...state, response: action.payload }
        case TYPE.GET_CRON_LOGS:
            state.getCronLogs = response
            state.isGetCronLogs = false
            return { ...state, response: action.payload }


        default:
            return state;

    }
}

