import { TYPE } from "../actions/types";
const initialState = {
  citiesAndHotelsList: [],
};


export default function (state = initialState, action) {
  switch (action.type) {
    case TYPE.CITIES_AND_HOTELS_LIST.GET:
      state.citiesAndHotelsList = action.payload;
      return {
        ...state,
      };

    default:
      return state;
  }
}

