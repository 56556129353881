import { TYPE } from '../actions/types'
const initialState = {
    loginResponseFromApi: {},
    loginUsingOtp: {}
}


export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.LOGIN:
            state.loginResponseFromApi = response
            return { ...state, response: action.payload }
        case TYPE.LOGOUT:
            return { ...state, response: action.payload }
        case TYPE.CHANGE_PASSWORD:
            return { ...state, response: action.payload }
        case TYPE.SEND_OTP_TO_CHANGE_PASSWORD:
            return { ...state, response: action.payload }
        case TYPE.CHANGE_PASSWORD_USING_OTP:
            return { ...state, response: action.payload }
        case TYPE.SESSION_VALID:
            return { ...state, response: action.payload }
        case TYPE.VERIFY_OTP_LOGIN:
            state.loginUsingOtp = response
            return { ...state, response: action.payload }
        case "clear_login_responses":
            state.loginResponseFromApi = {}
            state.loginUsingOtp = {}

        default:
            return state;
    }
}

