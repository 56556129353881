import { TYPE } from '../actions/types'

const initialState = {
    bookingsData: [],
    loading: true,
    couponData: {},
    bookingDetailsObj: {},
    isLoadingBookingDetails: false
}

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.GET_ADMIN_BOOKINGS:
            state.bookingsData = response
            state.loading = false
            return {
                ...state,
                response: action.payload
            }
        case TYPE.GET_BOOKING_COUNT:
            return { ...state, response: action.payload }
        case TYPE.GET_BOOKING_ID_DETAILS:
            state.bookingDetailsObj = response
            state.isLoadingBookingDetails = false
            return { ...state, response: action.payload }
        case TYPE.UPDATE_CHECKIN_COMPLETE:
            return { ...state, response: action.payload }
        case TYPE.UPDATE_CANCEL_CHECKIN:
            return { ...state, response: action.payload }
        //PENDING BOOKING
        case TYPE.REVERIFY_BOOKING_TRANSACTION:
            return { ...state, response: action.payload }
        case TYPE.CANCEL_BOOKING:
            return { ...state, response: action.payload }
        case TYPE.RESEND_COMMUNICATIONS:
            return { ...state, response: action.payload }
        case TYPE.BOOKING_TRANSFER:
            return { ...state, response: action.payload }
        case TYPE.ADD_UPDATE_CANCELLATION_COMMENT:
            return { ...state, response: action.payload }
        case TYPE.GET_ADMIN_PACKAGE:
            return { ...state, response: action.payload }
        case TYPE.GET_PACKAGE_COUNT:
            return { ...state, response: action.payload }
        case TYPE.RESEND_COMMUNICATIONS_PACKAGE:
            return { ...state, response: action.payload }
        case TYPE.GET_PACKAGE_ID_DETAILs:
            return { ...state, response: action.payload }
        case TYPE.UPDATE_PACKAGE_CHECKIN_COMPLETE:
            return { ...state, response: action.payload }
        case TYPE.CANCEL_PACKAGE:
            return { ...state, response: action.payload }
        case TYPE.CREDIT_TRANSFER:
            return { ...state, response: action.payload }
        // pending
        case TYPE.PACKAGE_CANCELLATION_COMMENT:
            return { ...state, response: action.payload }
        //CREATE_TRC_COUPON
        case TYPE.CREATE_TRC_COUPON.POST:
            return { ...state, response: action.payload }
        case TYPE.SEARCH_COUPON:
            state.couponData = response
            return {
                ...state,
                response: action.payload
            }
        case "clear_bookings_list":
            state.bookingsData = []
            state.loading = true
        default:
            return state;
    }
}

