import { TYPE } from '../actions/types'

const initialState = {
    createAdditionalPolicy: {},
    isCreateAdditionalPolicy: false,
    updateAdditionalPolicy: {},
    isUpdateAdditionalPolicy: false,
    removeAdditionalPolicy: {},
    isRemoveAdditionalPolicy: false,


}

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.CREATE_ADDITIONAL_POLICY:
            state.createAdditionalPolicy = response
            state.isCreateAdditionalPolicy = false
            return { ...state, response: action.payload }
        case TYPE.UPDATE_ADDITIONAL_POLICY:
            state.updateAdditionalPolicy = response
            state.isUpdateAdditionalPolicy = false
            return { ...state, response: action.payload }
        case TYPE.REMOVE_ADDITIONAL_POLICY:
            state.removeAdditionalPolicy = response
            state.isRemoveAdditionalPolicy = false
            return { ...state, response: action.payload }


        default:
            return state;

    }
}

