import { TYPE } from "../actions/types";

const initialState = {
    hotelPopularityList: [],
    holidaHotelPopularityList: [],
    hotelPopilarityApiStatus: {},
    loading: false,
    bulkPopularityUpdateRes:{},
    holidaBulkPopularityUpdateRes:{}


}

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.HOTELS_POPULARITY_LIST:
            state.hotelPopularityList = response.data
            state.hotelPopilarityApiStatus = response
            state.loading = false
            return { ...state, response: action.payload }
        case TYPE.HOTEL_HOLIDA_POPULARITY_LIST:
            state.holidaHotelPopularityList = response.data
            state.hotelPopilarityApiStatus = response
            state.loading = false
            return { ...state, response: action.payload }
        case TYPE.BULK_POPULARITY_UPDATE.PUT:
            state.bulkPopularityUpdateRes = response
            return { ...state, response: action.payload }
        case TYPE.BULK_UPDATE_HOLIDA_POPULARITY:
            state.holidaBulkPopularityUpdateRes = response
            return { ...state, response: action.payload }
        case "clear_popularity_response":
            state.hotelPopularityList = []
            state.hotelPopilarityApiStatus = {}
            state.loading = false
            state.bulkPopularityUpdateRes = {}
        case "clear_holida_popularity_response":
            state.holidaHotelPopularityList = []
            state.hotelPopilarityApiStatus = {}
            state.loading = false
            state.holidaBulkPopularityUpdateRes = {}
        default:
            return state
    }



}