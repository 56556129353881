import React, { useEffect, useCallback, useState } from "react";
import { Sidebar, Menu, Image } from "semantic-ui-react"
import {
    USER_DATA,
    SELECTED_HOTEL_ID,
    SELECTED_HOTEL_NAME
} from "../../../constants";
import { logout } from "../../../redux/actions/user_actions";
import { clearSession } from "../../../redux/actions/session_actions";
import adminImages from "../../../assets";

function CustomSideNavBar(props) {
    const [selectedModule, setSelectedModule] = useState("")
    const [showMarketingOptions, setShowMarketingOptions] = useState(false)
    const [showAddOptions, setShowAddOptions] = useState(false)
    const [showMoreOptions, setShowMoreOptions] = useState("")

    useEffect(() => {
        setSelectedModule(props.location.pathname)
    }, [])


    const handleMenuItem = (value) => {
        setSelectedModule(value)

        // if (value !== "/password-change") {
        localStorage.setItem("path_name", value);
        // }

        props.history.push(value)
        props.closeSideNavBar()
    }

    const logoutUser = () => {
        let userData = JSON.parse(localStorage.getItem(USER_DATA));
        let data = {
            "login_user_id": userData.login_user_id,
            "login_user_name": userData.login_user_name,
            "login_user_type": userData.user_access_type
        }
        props.dispatch(logout(data));
        props.dispatch(clearSession());
        props.history.push("/login");
        props.dispatch({ type: "clear_login_responses" })

    }

    const hideSideNavBar = () => {
        props.closeSideNavBar()
        // setShowMarketingOptions(false)
        // setShowAddOptions(false)
    }

    const handleMoreOptions = (type) => {
        if (showMoreOptions !== type) {
            setShowMoreOptions(type)
        }
        else {
            setShowMoreOptions("")
        }
    }

    const getMenuListItems = () => {
        let menuArr = []
        let permissions = props.session?.data?.permissions
        let unlistedClosedLabel = ""
        let isHotelClosed = 0
       
        if (permissions !== undefined) {

            if (permissions.uep_hotel_listing === "1" && permissions.uep_closed_hotels === "1") {
                unlistedClosedLabel = "Unlisted/Closed Hotels"
                isHotelClosed = 1
            }
            else if (permissions.uep_hotel_listing === "1" && permissions.uep_closed_hotels === "0") {
                unlistedClosedLabel = "Unlisted Hotels"
            }
            else if (permissions.uep_hotel_listing === "0" && permissions.uep_closed_hotels === "1") {
                unlistedClosedLabel = "Closed Hotels"
            }

            menuArr.push(
                <div key={'menuList'} className="custom_side_nav_bar_content" >

                    {permissions.uep_hotel_profile === "1" &&
                        <div className="menu_item_cls"
                            style={{ backgroundColor: selectedModule === "/profile" && "#3397F3", }}
                            onClick={() => handleMenuItem("/profile")}
                        >
                            <div>
                                <span className="menu_item_txt" style={{ color: selectedModule === "/profile" && "#ffffff" }}> Hotel Profile</span>
                            </div>
                        </div>
                    }

                    {permissions.uep_admin_dashboard === "1" &&
                        <div className="menu_item_cls"
                            style={{ backgroundColor: selectedModule === "/admin-dashboard-new" && "#3397F3", }}
                            onClick={() => handleMenuItem("/admin-dashboard-new")}
                        >
                            <div>
                                <span className="menu_item_txt" style={{ color: selectedModule === "/admin-dashboard-new" && "#ffffff" }}> Brevistay Bookings</span>
                            </div>
                        </div>
                    }

                    {permissions.uep_admin_dashboard === "1" &&
                        <div className="menu_item_cls"
                            style={{ backgroundColor: selectedModule === "/holida-dashboard" && "#3397F3", }}
                            onClick={() => handleMenuItem("/holida-dashboard")}
                        >
                            <div>
                                <span className="menu_item_txt" style={{ color: selectedModule === "/holida-dashboard" && "#ffffff" }}> Holida Bookings</span>
                            </div>
                        </div>
                    }

                    {permissions.uep_hotel_profile == "1" &&
                        <div className="menu_item_cls"
                            style={{ backgroundColor: selectedModule === "/property-search" && "#3397F3", }}
                            onClick={() => handleMenuItem("/property-search")}
                        >
                            <div>
                                <span className="menu_item_txt" style={{ color: selectedModule === "/property-search" && "#ffffff" }}> Property Search</span>
                            </div>
                        </div>
                    }


                    {permissions.uep_analytics !== "0" &&
                        <>
                            <div className="menu_item_cls"
                                style={{ backgroundColor: selectedModule === "/analytics" && "#3397F3", marginBottom: 0 }}
                                onClick={() => handleMenuItem("/analytics")}
                            >
                                <div>
                                    <span className="menu_item_txt" style={{ color: selectedModule === "/analytics" && "#ffffff" }}> Analytics </span>
                                </div>
                            </div>
                        </>

                    }

                    <div className="menu_item_divider" />



                    {permissions.uep_user_management === "1" &&
                        <div className="menu_item_cls"
                            style={{ backgroundColor: selectedModule === "/user-permissions" && "#3397F3", }}
                            onClick={() => handleMenuItem("/user-permissions")}
                        >
                            <div>
                                <span className="menu_item_txt"
                                    style={{ color: selectedModule === "/user-permissions" && "#ffffff" }}
                                >
                                    User Permissions
                                </span>
                            </div>
                        </div>
                    }

                    {permissions.uep_hotel_listing === "1" || permissions.uep_closed_hotels === "1" ?
                        <div className="menu_item_cls"
                            style={{ backgroundColor: selectedModule === "/unlisted-hotels" && "#3397F3", }}
                            onClick={() => handleMenuItem("/unlisted-hotels")}
                        >
                            <div>
                                <span className="menu_item_txt"
                                    style={{ color: selectedModule === "/unlisted-hotels" && "#ffffff" }}
                                >
                                    {isHotelClosed == 1 ? "Unlisted/Closed Hotels" : unlistedClosedLabel}
                                </span>
                            </div>
                        </div>
                        : null
                    }

                    {permissions.uep_hotel_listing === "1" || permissions.uep_closed_hotels === "1" ?
                        <>
                            <div className="menu_item_cls"
                                style={{ backgroundColor: selectedModule === "/location" && "#3397F3", marginBottom: 0 }}
                                onClick={() => handleMenuItem("/location")}
                            >
                                <div>
                                    <span className="menu_item_txt" style={{ color: selectedModule === "/location" && "#ffffff" }}> Location </span>
                                </div>
                            </div>
                        </>
                        : null
                    }

                    <div className="menu_item_divider" />


                    {permissions.uep_finance_inhouse == "1" || permissions.uep_finance == "1" ?
                        <div className="menu_item_cls"
                            style={{ backgroundColor: selectedModule === "/finance-new" && "#3397F3", }}
                            onClick={() => handleMenuItem("/finance-new")}
                        >
                            <div>
                                <span className="menu_item_txt"
                                    style={{ color: selectedModule === "/finance-new" && "#ffffff" }}
                                >
                                    Finance
                                </span>
                            </div>
                        </div>
                        : null
                    }

                    {permissions.uep_finance_inhouse == "1" &&
                        <div className="menu_item_cls"
                            style={{ backgroundColor: selectedModule === "/hotel-balance-list" && "#3397F3", marginBottom: 0 }}
                            onClick={() => handleMenuItem("/hotel-balance-list")}
                        >
                            <div>
                                <span className="menu_item_txt"
                                    style={{ color: selectedModule === "/hotel-balance-list" && "#ffffff" }}
                                >
                                    Payouts
                                </span>
                            </div>
                        </div>
                    }

                    <div className="menu_item_divider" />

                    {permissions.uep_marketing == "1" &&
                        <>
                            <div className={`sideBar_marketing_options_cls ${showMoreOptions === "marketing" ? "open" : ""}`} >
                                <div onClick={() => handleMoreOptions("marketing")} style={{ display: 'flex', width: "100%", justifyContent: 'space-between', cursor: 'pointer' }}>
                                    <div>
                                        <span className="menu_item_txt">
                                            Marketing Options
                                        </span>
                                    </div>

                                    <div className={`sideBar_marketing_arrow ${showMoreOptions === "marketing" ? "open" : ""}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                                            <path d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z" fill="#262626" />
                                        </svg>
                                    </div>
                                </div>
                                {showMoreOptions === "marketing" &&
                                    <div className="sideBar_marketing_options_container" >
                                        {permissions.uep_marketing === "1" &&
                                            <div className="menu_item_cls"
                                                style={{ backgroundColor: selectedModule === "/coupon" && "#3397F3", }}
                                                onClick={() => handleMenuItem("/coupon")}
                                            >
                                                <div>
                                                    <span className="menu_item_txt" style={{ color: selectedModule === "/coupon" && "#ffffff" }}> Coupons </span>
                                                </div>
                                            </div>
                                        }

                                        {permissions.uep_marketing === "1" &&
                                            <div className="menu_item_cls"
                                                style={{ backgroundColor: selectedModule === "/seo" && "#3397F3",marginBottom:0 }}
                                                onClick={() => handleMenuItem("/seo")}
                                            >
                                                <div>
                                                    <span className="menu_item_txt" style={{ color: selectedModule === "/seo" && "#ffffff" }}> SEO </span>
                                                </div>
                                            </div>
                                        }

                                        {/* {permissions.uep_marketing === "1" &&
                                            <div className="menu_item_cls"
                                                style={{ backgroundColor: selectedModule === "/marketing-campaign" && "#3397F3", marginBottom: 0 }}
                                                onClick={() => handleMenuItem("/marketing-campaign")}
                                            >
                                                <div>
                                                    <span className="menu_item_txt" style={{ color: selectedModule === "/marketing-campaign" && "#ffffff" }}> Campaign </span>
                                                </div>
                                            </div>
                                        } */}
                                    </div>
                                }
                            </div>
                            <div className="menu_item_divider" />
                        </>
                    }

                    {(permissions.uep_s3_bucket === "0" &&
                        permissions.uep_a_site_user === "0" &&
                        permissions.uep_activity_logs === "0") ? null :
                        <>
                            <div className={`sideBar_additional_options_cls ${showMoreOptions === "additional" ? "open" : ""}`} >
                                <div onClick={() => handleMoreOptions("additional")} style={{ display: 'flex', width: "100%", justifyContent: 'space-between', cursor: 'pointer' }}>
                                    <div>
                                        <span className="menu_item_txt">
                                            Additional Options
                                        </span>
                                    </div>

                                    <div className={`sideBar_marketing_arrow ${showMoreOptions === "additional" ? "open" : ""}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                                            <path d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z" fill="#262626" />
                                        </svg>
                                    </div>
                                </div>

                                {showMoreOptions === "additional" &&
                                    <div className="sideBar_marketing_options_container" >

                                        {permissions.uep_system_administration === "1" &&
                                            <div className="menu_item_cls"
                                                style={{ backgroundColor: selectedModule === "/system-admininstrator" && "#3397F3", }}
                                                onClick={() => handleMenuItem("/system-admininstrator")}
                                            >
                                                <div>
                                                    <span className="menu_item_txt"
                                                        style={{ color: selectedModule === "/system-admininstrator" && "#ffffff" }}
                                                    >
                                                        System Administrator
                                                    </span>
                                                </div>
                                            </div>
                                        }

                                        {permissions.uep_s3_bucket === "1" &&
                                            <div className="menu_item_cls"
                                                style={{ backgroundColor: selectedModule === "/s3" && "#3397F3", }}
                                                onClick={() => handleMenuItem("/s3")}
                                            >
                                                <div>
                                                    <span className="menu_item_txt" style={{ color: selectedModule === "/s3" && "#ffffff" }}> Manage S3 </span>
                                                </div>
                                            </div>
                                        }

                                        {permissions.uep_a_site_user === "1" &&
                                            <div className="menu_item_cls"
                                                style={{ backgroundColor: selectedModule === "/site-user" && "#3397F3", }}
                                                onClick={() => handleMenuItem("/site-user")}
                                            >
                                                <div>
                                                    <span className="menu_item_txt" style={{ color: selectedModule === "/site-user" && "#ffffff" }}> Site User </span>
                                                </div>
                                            </div>
                                        }

                                        {permissions.uep_activity_logs == "1" &&
                                            <div className="menu_item_cls"
                                                style={{ backgroundColor: selectedModule === "/activity-logs" && "#3397F3", marginBottom: 0 }}
                                                onClick={() => handleMenuItem("/activity-logs")}
                                            >
                                                <div>
                                                    <span className="menu_item_txt" style={{ color: selectedModule === "/activity-logs" && "#ffffff" }}> Activity Log </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    }
                    <div className="menu_item_divider" />
                </div>
            )
        }

        return menuArr
    }

    return (
        <Sidebar
            as={Menu}
            animation="scale down"
            onHide={() => hideSideNavBar()}
            vertical
            style={{ borderWidth: 0 }}
            visible={props.sidebarVal}
            className="side_nav_bar_parent_cls"
        >
            <div className="custom_side_nav_bar_header">
                <div style={{ marginTop: "0.875rem", width: "90%", display: 'flex', justifyContent: 'flex-end', top: '1px', position: 'fixed' }}>
                    <svg style={{cursor:"pointer"}} onClick={hideSideNavBar} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#F4F4F4" />
                    </svg>
                </div>
                <svg className="brevistay_nav_bar_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 50" fill="none">
                    <path d="M61.6225 20.0317C62.9207 20.3113 63.9635 20.9672 64.7509 21.9994C65.5383 23.0101 65.9321 24.1713 65.9321 25.4831C65.9321 27.3754 65.2723 28.8807 63.9528 29.999C62.6546 31.0957 60.835 31.6441 58.494 31.6441H49.247C48.5888 31.6441 48.0552 31.1105 48.0552 30.4523V10.1918C48.0552 9.53358 48.5888 9 49.247 9H58.1428C60.42 9 62.1971 9.52686 63.474 10.5806C64.7722 11.6343 65.4213 13.0643 65.4213 14.8707C65.4213 16.2039 65.0701 17.3114 64.3678 18.1931C63.6868 19.0748 62.7717 19.6876 61.6225 20.0317ZM53.514 18.1608H57.0894C57.9832 18.1608 58.6643 17.9673 59.1325 17.5802C59.6219 17.1716 59.8667 16.5803 59.8667 15.8061C59.8667 15.032 59.6219 14.4406 59.1325 14.032C58.6643 13.6234 57.9832 13.4191 57.0894 13.4191H53.514V18.1608ZM57.5363 27.1927C58.4514 27.1927 59.1537 26.9884 59.6432 26.5798C60.154 26.1497 60.4094 25.5368 60.4094 24.7412C60.4094 23.9455 60.1434 23.3219 59.6113 22.8703C59.1005 22.4187 58.3876 22.1929 57.4725 22.1929H53.514V27.1927H57.5363Z" fill="white" />
                    <path d="M157.071 38.6822L156.649 38.5944L156.607 36.3991L156.579 34.1892L157.562 34.2477C158.096 34.277 158.77 34.2624 159.037 34.2185C159.669 34.0867 160.441 33.2672 160.905 32.2427L161.256 31.4524L160.582 29.6084C160.217 28.5985 159.669 27.0765 159.36 26.2276C159.051 25.3788 158.545 24.0031 158.236 23.1542C157.927 22.3054 157.52 21.2224 157.351 20.7394C156.031 17.1977 155.315 15.1927 155.315 15.0902C155.315 15.017 156.537 14.9585 158.026 14.9585H160.722L162.028 19.7589C162.745 22.3932 163.405 24.969 163.489 25.4959L163.657 26.4472L163.784 25.7886C163.91 25.1885 164.837 21.6468 165.16 20.5931C165.23 20.3589 165.582 19.1003 165.933 17.8124C166.284 16.5245 166.607 15.3536 166.635 15.2219C166.705 14.9878 166.958 14.9585 169.36 14.9585H172L171.438 16.5976C171.129 17.505 170.567 19.1734 170.174 20.3004C169.795 21.4273 169.416 22.5103 169.36 22.7152C169.289 22.9201 169.051 23.6372 168.826 24.325C168.587 25.0129 168.082 26.4911 167.702 27.618C167.309 28.7449 166.705 30.5158 166.354 31.5695C164.711 36.3698 163.25 38.1261 160.455 38.6529C159.556 38.8286 157.773 38.8432 157.071 38.6822Z" fill="white" />
                    <path d="M85.2962 31.5993C82.1452 31.1997 79.9987 29.23 79.2282 26.0329C78.953 24.8768 78.9943 21.5084 79.297 20.338C79.985 17.7261 81.3197 16.1846 83.59 15.3854C84.5394 15.0428 84.8971 15 86.3281 15C87.7591 15 88.1306 15.0571 89.0663 15.3854C90.8687 16.0134 91.8869 16.9268 92.6024 18.5397C93.1391 19.6958 93.3042 20.7519 93.3317 22.8929L93.3455 24.677L88.8186 24.7198C85.7365 24.7341 84.2504 24.8054 84.1816 24.9053C84.1404 24.991 84.1541 25.3193 84.2367 25.6475C84.4155 26.404 85.3099 27.3317 86.0667 27.5744C87.3876 27.974 89.9331 27.6743 91.6668 26.9035L92.3135 26.6181V30.5717L91.6393 30.8714C90.1808 31.5137 87.2775 31.8562 85.2962 31.5993ZM88.736 21.1659C88.736 21.0945 88.6672 20.7377 88.5847 20.4094C88.4058 19.6529 87.7591 18.9821 87.0298 18.768C85.6952 18.3969 84.6082 19.2105 84.2642 20.8661L84.1679 21.3229H86.452C87.9655 21.3229 88.736 21.2658 88.736 21.1659Z" fill="white" />
                    <path d="M121.055 31.665C120.083 31.5936 118.8 31.3508 117.926 31.0795L117.179 30.8367V28.6518C117.179 27.4523 117.207 26.467 117.236 26.467C117.264 26.467 117.701 26.6241 118.208 26.824C120.449 27.6808 123.071 27.9378 123.888 27.3952C124.72 26.8525 124.142 26.1243 122.183 25.2817C119.308 24.0394 118.307 23.2968 117.658 21.9402C117.151 20.912 117.052 19.4269 117.391 18.2559C117.574 17.599 117.785 17.2705 118.42 16.6137C119.11 15.9282 119.392 15.7568 120.421 15.4141C121.478 15.0428 121.816 15 123.381 15C125.298 15 126.425 15.2142 127.976 15.8568C128.469 16.071 128.878 16.2995 128.878 16.3852C128.878 16.5851 127.736 19.4983 127.595 19.6696C127.525 19.741 127.186 19.6696 126.778 19.4983C126.397 19.3412 125.523 19.0698 124.847 18.8985C123.014 18.4272 121.957 18.6843 122.169 19.5268C122.267 19.9267 122.563 20.1266 123.945 20.7406C127.257 22.2258 128.159 22.8684 128.751 24.225C129.188 25.2389 129.216 27.3238 128.807 28.4662C128.3 29.8657 126.89 31.0224 125.213 31.4365C124.494 31.6078 122.098 31.7364 121.055 31.665Z" fill="white" />
                    <path d="M134.476 31.9895C133.417 31.8245 132.276 31.2057 131.836 30.5457C130.969 29.2257 130.969 29.1432 130.914 24.468L130.873 20.164H129.099V17.9915L130.213 17.1802L131.341 16.3689L131.987 14.7326L132.647 13.0825L135.975 13V16.1764H139.701L139.619 20.0953L135.961 20.1778L136.044 27.2043L136.429 27.5343C136.896 27.9331 137.708 27.9744 138.904 27.6581C139.344 27.5481 139.729 27.4518 139.757 27.4518C139.798 27.4518 139.825 28.3044 139.825 29.3632V31.2607L139.248 31.4807C137.886 31.9895 135.865 32.1958 134.476 31.9895Z" fill="white" />
                    <path d="M144.529 31.6281C143.077 31.3716 141.892 30.3885 141.328 28.9209C141.088 28.2939 141.018 27.8095 141.018 26.6269C141.004 25.202 141.032 25.0738 141.469 24.1761C141.822 23.4494 142.089 23.1075 142.668 22.6943C144.021 21.7111 146.108 21.2124 148.886 21.2124H150.141L150.071 20.5C149.902 18.7189 148.252 18.377 145.305 19.5168C144.726 19.7448 144.176 19.93 144.092 19.93C143.852 19.93 142.484 16.5389 142.682 16.3822C142.95 16.1684 144.219 15.6697 145.093 15.4418C145.558 15.3278 146.687 15.1568 147.617 15.0713C151.072 14.7436 153.314 15.5415 154.569 17.5505C155.26 18.6619 155.317 19.2461 155.317 25.5867V31.4714H151.777L151.27 30.4027C151.002 29.8185 150.734 29.3341 150.705 29.3341C150.663 29.3341 150.226 29.7188 149.732 30.1747C149.168 30.7162 148.59 31.1152 148.097 31.3004C147.265 31.5996 145.403 31.7848 144.529 31.6281ZM149.041 27.496C149.803 27.0543 150.099 26.4416 150.099 25.2305V24.1619L149.013 24.2331C147.716 24.3043 146.926 24.6463 146.56 25.259C146.108 25.9999 146.249 27.1826 146.842 27.553C147.434 27.9235 148.308 27.9092 149.041 27.496Z" fill="white" />
                    <path d="M68.3394 23.4882L68.3768 15.2769L71.7911 15.1902L72.1276 16.4624C72.464 17.7634 72.6509 18.1827 72.7631 17.8357C72.8005 17.7201 73.0995 17.2719 73.4235 16.8093C73.8472 16.2311 74.2459 15.8552 74.8565 15.4938C75.6416 15.0312 75.7911 14.9878 76.7506 15.0022C77.3238 15.0167 77.8223 15.0456 77.8472 15.0745C77.8597 15.1034 77.7974 16.3033 77.6977 17.7345L77.4983 20.3367H76.1151C74.4204 20.3367 73.9344 20.568 73.3612 21.6378L72.9874 22.3317L72.95 27.0156L72.9001 31.6851H68.3145L68.3394 23.4882Z" fill="white" />
                    <path d="M98.5868 31.3631C98.3247 30.6606 93.3438 15.2488 93.3438 15.1171C93.3438 15.0585 94.4579 15.0146 95.8473 15.0293L98.3509 15.0732L99.583 20.0933C100.252 22.8449 100.854 25.4501 100.907 25.8746L100.999 26.6357L101.169 25.7575C101.405 24.6598 103.66 15.7757 103.83 15.3366C103.948 15 103.987 15 106.399 15H108.837L108.772 15.3366C108.745 15.5123 108.522 16.2148 108.287 16.9027C108.064 17.5906 107.828 18.3077 107.762 18.5127C107.697 18.7176 107.461 19.4347 107.238 20.1226C107.015 20.8105 106.779 21.5277 106.714 21.7326C106.648 21.9375 106.412 22.6547 106.189 23.3425C105.966 24.0304 105.717 24.7476 105.665 24.9525C105.56 25.3038 105.455 25.6258 104.616 28.1724C104.394 28.8603 104.053 29.9288 103.856 30.5581L103.515 31.6851H101.117C98.7441 31.6851 98.7048 31.6851 98.5868 31.3631Z" fill="white" />
                    <path d="M110.028 15L112.386 15.0293L114.731 15.0732L114.77 23.3865L114.795 31.6851H110.028V15Z" fill="white" />
                    <path d="M111.624 13.7087C110.519 13.4527 110.028 12.7129 110.028 11.3473C110.028 9.62594 110.679 9 112.446 9C113.391 9 113.611 9.04268 113.967 9.32719C114.593 9.81087 114.838 10.4653 114.789 11.518C114.74 12.5422 114.47 13.0686 113.82 13.4385C113.317 13.723 112.225 13.851 111.624 13.7087Z" fill="white" />
                    <path d="M21 0L0 8.98876V20.4629C0 33.7746 8.42691 45.6273 21 50C23.057 49.2488 24.9962 48.2912 26.7974 47.1557L26.044 44.7974C25.451 44.6219 24.7525 44.1865 24.143 43.8066C23.9494 43.686 23.7649 43.571 23.5956 43.4716L20.5696 45.741L17.4417 42.502L19.8164 39.5559C19.4289 38.8392 19.13 38.078 18.9262 37.2891L15.1781 36.7543L15.2567 32.2531L19.0212 31.8482C19.2524 31.067 19.5777 30.3167 19.99 29.6139L17.7194 26.5879L20.9585 23.46L23.9045 25.8347C24.6213 25.4472 25.3825 25.1483 26.1714 24.9445L26.7062 21.1964L31.2074 21.275L31.6122 25.0395C32.3935 25.2707 33.1438 25.596 33.8466 26.0083L36.8726 23.7377L40.0005 26.9768L37.6257 29.9228C38.0132 30.6396 38.3122 31.4008 38.516 32.1897H39.0234C39.0157 32.2133 39.0079 32.237 39.0001 32.2606C40.299 28.8599 41 25.1922 41 21.4049V8.98876L21 0Z" fill="white" />
                    <path d="M28.6425 39.2406C27.7523 39.2251 26.8866 38.9459 26.155 38.4384C25.4234 37.9308 24.8588 37.2178 24.5324 36.3893C24.2061 35.5609 24.1328 34.6543 24.3217 33.7842C24.5106 32.9141 24.9533 32.1195 25.5938 31.501C26.2343 30.8825 27.0438 30.4678 27.92 30.3093C28.7962 30.1509 29.6997 30.2558 30.5162 30.6109C31.3328 30.9659 32.0257 31.5551 32.5074 32.304C32.9891 33.0528 33.2379 33.9277 33.2223 34.8179C33.2015 36.0117 32.7073 37.1484 31.8484 37.9778C30.9895 38.8072 29.8363 39.2614 28.6425 39.2406Z" fill="white" />
                    <path d="M52.9749 49.7965L52.503 47.351H50.3006L49.8429 49.7965H48.1982L50.2291 40H52.6174L54.6339 49.7965H52.9749ZM50.5723 45.8922H52.217L51.3875 41.5446L50.5723 45.8922Z" fill="white" />
                    <path d="M76.9261 42.2453C76.9261 41.7019 76.6305 41.4302 76.0394 41.4302H74.8523V48.3092H76.0394C76.6305 48.3092 76.9261 48.0041 76.9261 47.3939V42.2453ZM73.1362 40H76.2539C77.0166 40 77.603 40.1907 78.013 40.5721C78.4325 40.9439 78.6422 41.4826 78.6422 42.1881V47.5226C78.6422 49.0386 77.8747 49.7965 76.3397 49.7965H73.1362V40Z" fill="white" />
                    <path d="M105.177 40V49.7965H103.518V45.7349C103.518 44.7719 103.585 43.8042 103.718 42.8317L102.117 47.2652H100.687L99.0705 42.8317C99.2135 43.7184 99.285 44.6861 99.285 45.7349V49.7965H97.6403V40H99.4709L101.402 45.4346L103.332 40H105.177Z" fill="white" />
                    <path d="M124.309 40H126.04V49.7965H124.309V40Z" fill="white" />
                    <path d="M149.359 40H151.06V49.7965H149.33C147.928 46.2212 147.128 44.1427 146.927 43.5611C146.727 42.9699 146.617 42.6458 146.598 42.5886C146.598 42.6076 146.622 42.784 146.67 43.1177C146.794 43.9854 146.856 45.058 146.856 46.3356V49.7965H145.168V40H146.927C148.138 43.0605 148.839 44.8625 149.03 45.406C149.22 45.9494 149.359 46.3689 149.444 46.6645C149.54 46.9505 149.597 47.1174 149.616 47.1651C149.616 47.1269 149.592 46.9458 149.545 46.6216C149.421 45.6682 149.359 44.586 149.359 43.3752V40Z" fill="white" />
                </svg>
            </div>


            {getMenuListItems()}

            <div className="sidebar_footer" >
                <div style={{ height: "3.5", backgroundColor: "#262626", width: "3.125rem", borderRadius: "50%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Image src={adminImages.profileImage} style={{ height: "100%", width: "100%" }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: "space-between", marginLeft: "1rem", height: "3.125" }}>
                    <div>
                        <p className="sideBar_profile_name_txt">{props.session?.data?.login_user_name}</p>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <span onClick={() => handleMenuItem("/password-change")} className="menu_item_txt" style={{ color: "#3397F3", cursor: "pointer" }}>
                                Change Password
                            </span>
                        </div>
                        <div style={{ marginLeft: "1.25rem" }}>
                            <span onClick={() => logoutUser()} className="menu_item_txt" style={{ color: "#F73531", cursor: "pointer" }}>
                                Logout
                            </span>
                        </div>
                    </div>
                </div>
            </div>


        </Sidebar>
    )
}

export default CustomSideNavBar