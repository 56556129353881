import { TYPE } from "../actions/types";

const initialState = {
  hotelProfileData: [],
  userAccess : [],
  loading: true,
  updateHotelData:{},
  customerReviews:{},
  customerReviewsLoader:true,
  sendWelcomeMailRes:{},
  addGeneralCommRes:{},
  getStatusCommentRes:{}
}

export default function (state = initialState, action, response = action.payload) {
  switch (action.type) {
    case TYPE.SA_GET_BASIC_SETTINGS:
      state.hotelProfileData = response
      if (response.status === "SUCCESS"){
        state.userAccess = response.hotel_profile.user_access
        let linkedChannelManager = response.hotel_profile.linked_channel_managers

        console.log("linkedChannelManager",linkedChannelManager);

        localStorage.setItem("linked_channel_managers", JSON.stringify(linkedChannelManager))
      }
      state.loading = false
      return { ...state, response: action.payload };
    case TYPE.WELCOME:
      state.sendWelcomeMailRes = response
      return { ...state, response: action.payload };
    case TYPE.ADD_GENERAL_COMMENT.POSTß:
      return { ...state, response: action.payload };
    case TYPE.SA_UPDATE_HOTEL_SETTINGS:
      state.updateHotelData = response
      return { ...state, response: action.payload };
    case TYPE.GET_USER_REVIEWS_FOR_HOTEL:
      state.customerReviews = response
      state.customerReviewsLoader = false
      return { ...state, response:action.payload}
    case TYPE.GET_STATUS_COMMENT:
      state.getStatusCommentRes = response
      return { ...state, response:action.payload}
    case "clear_hotel_profile_response":
      state.hotelProfileData = []
      state.loading = true
    case "clear_update_hotel_settings":
      state.updateHotelData  = {}
    case "clear_customer_reviews":
      state.customerReviews = {}
      state.customerReviewsLoader = true
    case "clear_get_status_comment":
      state.getStatusCommentRes = {}
      return { ...state, response:action.payload}
    case TYPE.ADD_GENERAL_COMMENT.POST:
      state.addGeneralCommRes = action.payload;
      return {
        ...state,
      };
     case "clear_addGeneralCommRes_state":
      state.addGeneralCommRes={}
    default:
      return state;
  }
}
