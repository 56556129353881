import { TYPE } from "../actions/types";
const initialState ={
  paymentCommRes:{},
  }
export default function(state = initialState, action) {
  switch (action.type) {
    case TYPE.SA_UPDATE_HOTEL_SETTINGS:
      state.paymentCommRes=action.payload
      return { ...state, response: action.payload };
    case "clear_payment_response":
      state.paymentCommRes={}
     

    default:
      return state;
  }
}
