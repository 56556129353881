import { TYPE } from "../actions/types";
const initialState = {
  coupons: [],
  restrictionData:[],
  total: 1,
  errMsg: "",
  isGenCoupon: false,
  genErrMsg: "",
  tnc: [],
  userDetailsByMobile :[],
  couponMail:{},
  updateImageRes:{}
};

export default function (state = initialState, action) {

  switch (action.type) {
    case TYPE.COUPONS.GET:
      console.log("response",action.payload)
      return {
        ...state,
        coupons: action.payload.coupons,
        restrictionData:action.payload,
        total: action.payload.total,
      };
    case "COUPON_FETCHING_FAILED":
      return {
        ...state,
        coupons: [],
        errMsg: "Something went wrong",
      };

    case TYPE.COUPONS.PUT:
      return {
        ...state,
        updateCoupon: action.payload,
      };
    case TYPE.UPLOAD_COUPON_IMAGES:
      state.updateImageRes = action.payload
      return {
        ...state,
        updateCouponImages: action.payload,
      };
    case TYPE.COUPONS.POST:
      return {
        ...state,
        genCoupon: action.payload,
        isGenCoupon: true,
      };
    
    case "GENERATE_COUPON_FAILED":
      return {
        ...state,
        isGenCoupon: false,
        genErrMsg: action.payload.msg,
      };

      case TYPE.COUPON_MAIL.POST:
        console.log("coupon mail response",action.payload)
      return {
        ...state,
        couponMail: action.payload
      };
    case "clear_coupon_data":
      state.updateImageRes = {}
      return{
        ...state
      }

    default:
      return state;
  }
}

