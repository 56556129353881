import React, { Component } from "react";

import Header from "../components/Header_footer/Header";
import Footer from "../components/Header_footer/Footer";

import { connect } from "react-redux";

import {
  bankAccountResponse,
} from "../redux/actions/bank_account_actions";

class Layout extends Component {
  render() {
    return (
      <div>
        {this.props.bankAccountResponse.response == 1 ?
          <div>
            <Header/>
            {this.props.children}
            {/* <Footer /> */}
          </div>
          :
          <div>
            <Header />
            {this.props.children}
          </div>
        }
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    bankAccountResponse: state.bankAccountResponse
  };
};


export default connect(mapStateToProps)(Layout);
