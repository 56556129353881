import { updateCheckin } from "../actions/checkin_actions";
import { TYPE } from "../actions/types";
const intialState = {
  checkinData: {},
  specificCheckinData: {},
  updateCheckinRes: {},
};
export default function (state = intialState, action) {
  switch (action.type) {
    case TYPE.GET_HOTEL_CHECKIN:
      state.checkinData = action.payload;
      return { ...state, response: action.payload };
    // this case is for specific date
    case TYPE.GET_SPECIFIC_HOTEL_CHECKIN:
      state.specificCheckinData = action.payload;
      return { ...state, response: action.payload };
    case TYPE.UPDATE_HOTEL_CHECKIN:
      state.updateCheckinRes = action.payload;
      return { ...state, response: action.payload };
    // this case is for specific date
    case "clear_specific_checkin_data":
      state.specificCheckinData = {};
      return { ...state, response: action.payload };
    case "clear_update_checkin_res":
      console.log("check in response clear ");
      state.updateCheckinRes = {};
      return { ...state, response: action.payload };
    case "clear_checkin_data":
      state.checkinData = {};
      return { ...state, response: action.payload };
    default:
      return state;
  }
}
