import { TYPE } from "../actions/types";
const initialState = {
  userDataList: [],
  walletTransactionsList: [],
  loading: false,
  deleteusermsg: {
    status: "",
    msg: "",
  },
  deleteUserResponse :{}
};

export default function (
  state = initialState,
  action,
  response = action.payload
) {
  switch (action.type) {
    case TYPE.USER_DETAILS.GET:
      state.userDataList = response;
      return {
        ...state,
        loading: false,
      };
    case "clear_user_site_state":
      state.userDataList = [];

      return {
        ...state,
        loading: false,
      };
    case "set_loading":
      state.loading = true

      return {
        ...state
      }
    case TYPE.WALLET_TRANSACTIONS.GET:
      state.walletTransactionsList = response;
      return {
        ...state,
        loading: false,
      };
    case TYPE.DELETE_USER.PUT:
      state.deleteUserResponse = response;
      if (response.status == "SUCCESS") {
        state.deleteusermsg.status = "SUCCESS";
        state.deleteusermsg.msg = "User Deleted Successfully";
      } else {
        state.deleteusermsg.status = "ERROR";
        state.deleteusermsg.msg = "Some Error Occured";
      }
      return {
        ...state,
      };
    case "clear_state":
      state.deleteusermsg.status = "";
      state.deleteusermsg.msg = "";

      return {
        ...state,
      };
    case "clear_wallet_list":
      state.walletTransactionsList = [];
      return {
        ...state,
      };
    default:
      return state;
  }
}
