import { TYPE } from "../actions/types";
const intialState ={
  bookingExcelRes:{},
  analyticsExcelRes:{}
}
export default function (state = intialState, action) {
  switch (action.type) {
    case TYPE.DOWNLOAD_BOOKING_DATA_ADMIN:
      state.bookingExcelRes=action.payload
      return { ...state, response: action.payload };
    case TYPE.DOWNLOAD_ANALYTICS_DATA:
      state.analyticsExcelRes=action.payload
      return { ...state, response: action.payload };
    case "clear_analytics_excel_res":
      state.analyticsExcelRes={}
      return { ...state, response: action.payload };
    case "clear_booking_excel_res":
      state.bookingExcelRes={}
      return { ...state, response: action.payload };
    default:
      return state;
  }
}
