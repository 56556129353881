import { TYPE } from '../actions/types'

const initialState = {
    getPlatformChargeAndStatus: {},
    isPlatformChargeAndStatus: false,
    updatePlatformChargeStatus: {},
    isUpdatePlatformChargeStatus: false,
    updatePlatformChargeData: {},
    isUpdatePlatformChargeData: false,


}

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.GET_PLATFORM_CHARGE_AND_STATUS:
            state.getPlatformChargeAndStatus = response
            state.isPlatformChargeAndStatus = false
            return { ...state, response: action.payload }
        case TYPE.UPDATE_PLATFORM_CHARGE_DATA:
            state.updatePlatformChargeData = response
            state.isUpdatePlatformChargeData = false
            return { ...state, response: action.payload }
        case TYPE.UPDATE_PLATFORM_CHARGE_STATUS:
            state.updatePlatformChargeStatus = response
            state.isUpdatePlatformChargeStatus = false
            return { ...state, response: action.payload }


        default:
            return state;

    }
}

