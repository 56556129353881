import { TYPE } from "../actions/types";
const intialState = {
  hotelRoomsData: {},
  updateRoomResponse: {},
  specificHotelRoomsData: {},
};
export default function (state = intialState, action) {
  switch (action.type) {
    case TYPE.GET_HOTEL_ROOMS:
      state.hotelRoomsData = action.payload;
      return { ...state, response: action.payload };
    // this case is for specific date
    case TYPE.GET_SPECIFIC_HOTEL_ROOMS:
      state.specificHotelRoomsData = action.payload;
      return { ...state, response: action.payload };
    case TYPE.UPDATE_HOTEL_ROOMS:
      state.updateRoomResponse = action.payload;
      return { ...state, response: action.payload };
    // this case is for specific date
    case "clear_specific_hotel_room":
      state.specificHotelRoomsData = {};
      return { ...state, response: action.payload };
    case "clear_update_room_response":
      state.updateRoomResponse = {};
      return { ...state, response: action.payload };
    case "clear_hotel_rooms_data":
      state.hotelRoomsData = {};
      return { ...state, response: action.payload };
    default:
      return state;
  }
}
