import { TYPE } from '../actions/types'

const initialState = {
    updateStatus: {}
}

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.SWITCH_CHANNEL_MANAGER_GLOBAL_STATUS:
            state.updateStatus = response
            return {
                ...state,
                response: response
            }
        default:
            return state;
    }
}

