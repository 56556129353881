import { TYPE } from '../actions/types';
const intialState ={
    getCashbackRes:{},
    editCashbackRes:{}
}

export default function (state=intialState, action) {

    switch (action.type) {
        case TYPE.GET_CASHBACK_CONFIG:
            state.getCashbackRes=action.payload
            return { ...state, response: action.payload }
        case TYPE.EDIT_CASHBACK_CONFIG:
            state.editCashbackRes=action.payload
            return { ...state, response: action.payload }
        case "clear_edit_cashback_config":
            state.editCashbackRes={}
            return { ...state, response: action.payload }
            
        default:
            return state;
    }
}

