import { TYPE } from "../actions/types";
const initialState = {
  commisionData: {},
  updateCommissionRes: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case TYPE.GET_COMMISSIONS_DATA.GET:
      state.commisionData = action.payload;
      return { ...state, response: action.payload };
    case TYPE.UPDATE_COMMISSIONS.POST:
      state.updateCommissionRes = action.payload;
      return { ...state, response: action.payload };
    case "clear_update_commision_res":
      state.updateCommissionRes = {};
      return { ...state, response: action.payload };
    case "clear_commision_data":
      state.commisionData = {};
      return { ...state, response: action.payload };
    default:
      return state;
  }
}
