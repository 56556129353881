import { TYPE } from "../actions/types";

const intialState = {
  cityContent : {}
}
export default function (
  state =intialState, 
  action,
  response = action.payload
) {
  switch (action.type) {
    //Brevistay
    case TYPE.SEO_CITIES.GET:
      return { ...state, response: response };

    case TYPE.SEO.GET:
      return { ...state, response: response };

    case TYPE.SEO.POST:
      return { ...state, response: response };

    case TYPE.SEO.PUT:
      return { ...state, response: response };

    case TYPE.SEO_CITY_CONTENT.GET:
      state.cityContent = response.seo_city_content

      return { ...state, response: response };

    case TYPE.FAQS.POST:
      return { ...state, response: response };

    case TYPE.FAQS.PUT:
      return { ...state, response: response };

    case TYPE.SEO.DELETE:
      return { ...state, response: response };

    case TYPE.UPLOAD_SEO_IMAGE.POST:
      return { ...state, response: response };

    case TYPE.DELETE_FAQ.POST:
      return { ...state, response: response };

    //Holida
    case TYPE.GET_HOLIDA_SEO:
      return { ...state, response: response };

    case TYPE.CREATE_HOLIDA_SEO:
      return { ...state, response: response };

    case TYPE.GET_HOLIDA_CITIES:
      return { ...state, response: response };

    case TYPE.UPDATE_HOLIDA_SEO_CITY_CONTENT:
      return { ...state, response: response };

    case TYPE.GET_HOLIDA_SEO_CITY_CONTENT:
      return { ...state, response: response };

    case TYPE.CREATE_HOLIDA_FAQ:
      return { ...state, response: response };

    case TYPE.UPDATE_HOLIDA_FAQ:
      return { ...state, response: response };

    case TYPE.DELETE_HOLIDA_SEO:
      return { ...state, response: response };

    case TYPE.UPLOAD_HOLIDA_SEO_IMAGE:
      return { ...state, response: response };

    case TYPE.DELETE_HOLIDA_FAQ:
      return { ...state, response: response };

    default:
      return state;
  }
}
