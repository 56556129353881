import axios from 'axios';

import { TYPE } from './types';

import * as Constants from '../../constants';

export function linkedAccountHotelList(data) {
    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const loginApi = Constants.HOTEL_API + '/' + TYPE.LINKED_ACCOUNT_HOTEL_LIST;
    const request = axios
        .post(loginApi, data, {
            headers: { "Authorization": `Bearer ${apiToken}` }
        })
        .then(response => response.data);

    return {
        type: TYPE.LINKED_ACCOUNT_HOTEL_LIST,
        payload: request
    }
}

export function hideFooter(data) {
    let showFooter = data.showFooter 
    const request = showFooter
    return {
        type: "hideFooter",
        payload: request
    }
}

