import { TYPE } from "../actions/types";

const intialiState = {
  holdSoldOutRes: {},
  updateRemoveDateRes: {},
  updateUnsoldDateRes:{},
  updateAddDateRes: {},
  specificDateSoldOutRes: {},
};
export default function (state = intialiState, action) {
  switch (action.type) {
    case TYPE.GET_SOLD_OUT_DATES:
      state.holdSoldOutRes = action.payload;
      return { ...state, response: action.payload };
    // this case is for specific date
    case TYPE.GET_SPECIFIC_SOLD_OUT_DATES:
      state.specificDateSoldOutRes = action.payload;
      return { ...state, response: action.payload };
    case TYPE.UPDATE_SOLD_OUT_DATE:
      state.updateRemoveDateRes = action.payload;
      return { ...state, response: action.payload };
    case TYPE.HOTEL_SOLD_OUT:
      state.updateAddDateRes = action.payload;
      return { ...state, response: action.payload };
    case TYPE.HOTEL_UNSOLD_OUT:
      state.updateUnsoldDateRes=action.payload;
      return { ...state, response: action.payload };
    // this case is for specific date
    case "clear_specific_date_soldout_res":
      state.specificDateSoldOutRes = {};
      return { ...state, response: action.payload };
    case "clear_update_remove_date_res":
      state.updateRemoveDateRes = {};
      return { ...state, response: action.payload };
    case "clear_update_add_date_res":
      state.updateAddDateRes = {};
      return { ...state, response: action.payload };
    case "clear_update_unsold_date_res":
        state.updateUnsoldDateRes = {};
        return { ...state, response: action.payload };
    case "clear_hold_sold_out_res":
      state.holdSoldOutRes = {};
      return { ...state, response: action.payload };
    default:
      return state;
  }
}
