import axios from 'axios';

import { TYPE } from './types';

import * as Constants  from '../../constants';

export function getCheckin(data){
    let apiToken=JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const loginApi=Constants.HOTEL_API + '/' + TYPE.GET_HOTEL_CHECKIN;
    const request = axios
        .post(loginApi,data,{headers: { "Authorization" : `Bearer ${apiToken}`}
        })
        .then(response => response.data);
    
    return {
        type: TYPE.GET_HOTEL_CHECKIN,
        payload:request
    }
}
// this function is for specific date
export function getSpecificCheckin(data){
    let apiToken=JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const loginApi=Constants.HOTEL_API + '/' + TYPE.GET_HOTEL_CHECKIN;
    const request = axios
        .post(loginApi,data,{headers: { "Authorization" : `Bearer ${apiToken}`}
        })
        .then(response => response.data);
    
    return {
        type: TYPE.GET_SPECIFIC_HOTEL_CHECKIN,
        payload:request
    }
}
export function updateCheckin(data){
    let apiToken=JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const loginApi=Constants.HOTEL_API + '/' + TYPE.UPDATE_HOTEL_CHECKIN;
    const request = axios
        .post(loginApi,data,{headers: { "Authorization" : `Bearer ${apiToken}`}
        })
        .then(response => response.data);
    
    return {
        type: TYPE.UPDATE_HOTEL_CHECKIN,
        payload:request
    }
}


