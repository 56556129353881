import commentIcon from "./images/escalate/comment icon.svg";
import bookingChannelIcon from "./images/escalate/Booking Channel icon.svg";
import commentTabIcon from "./images/escalate/Comments tab icon.svg";
import escIcon from "./images/escalate/Escalate Icon.svg";
import escByIcon from "./images/escalate/Escalated by icon.svg";
import escReasonIcon from "./images/escalate/Escalation reason icon.svg";
import escStatus from "./images/escalate/Escalation status icon.svg";
import noComment from "./images/escalate/NoComments.png";
import trialLottie from "./lottie files/EscalationCheckBox.json";
import ErrorPage from "./images/escalate/Error fetching.png";
import saved from "./images/escalate/saved.svg";
import reviewIcon from "./images/Review/Review icon.svg";
import emptyStar from "./images/Review/Empty Star.svg";
import filledStar from "./images/Review/Filled Star.svg";
import noReview from "./images/Review/No review illustration.png";
import blackCross from "./images/Action modal/close.svg";
import CreateCoupon from "./images/Action modal/Create coupon.svg";
import creditCashback from "./images/Action modal/credit cashback.svg";
import transferBooking from "./images/Action modal/transfer booking.svg";
import tick from "./images/Action modal/Tick.svg";
import Refund from "./images/Action modal/Refund.svg";
import cancelBooking from "./images/Action modal/cancel booking.svg"
import errorIllustration from "./images/Action modal/ErrorIllustration.png";
import successIllustration from "./images/Action modal/SuccessIllustration.png";
import Confirmation from "./images/Action modal/confirmation.svg";
import Error from "./images/Action modal/Error.svg";
import noResultsImg from "./images/Action modal/NoResults.png";
import viewHotelImg from "./view Hotel.png"
import changeAdminImg from "./Change Admin.png"
import editHotelImg from "./Edit hotel data.png"
import inventoryImg from "./Update Inventory.png"
import welcomeImg from "./Welcome mail.png"
import commentsImg from "./Hotel Comments.png"
import GeneralCommentIcon from "./images/Action modal/Comments.svg";
import selectLocation from "./Select Location.png"
import emptyAdminImg from "./Empty Change Admin.png"
import oneStar from "./images/Review/Angry.png";
import twoStar from "./images/Review/Sad.png";
import threeStar from "./images/Review/Average.png";
import fourStar from "./images/Review/Good.png";
import fiveStar from "./images/Review/Excellent.png"
import dashBoardComment from "./images/booking dashboard/Comment icon.svg";
import dashBoardNoComment from "./images/booking dashboard/No Comments.svg";
import disableComment from "./images/booking dashboard/disable Comment.svg"
import editRooms from "./images/hotelInventory/Edit Popularity.svg";
import quickSoldOut from "./images/hotelInventory/Quick Soldout.svg";
import commissionIcon from "./images/hotelInventory/Commission.svg";
import ratesIcon from "./images/hotelInventory/Rates.svg";
import checkInIcon from "./images/hotelInventory/Check-in.svg";
import BulkPopularityImg from "./Bulk popularity.png";
import ImagePlaceholder from "./images/New Hotel Edit/Image placeholder.png";
import documentComplete from "./images/New Hotel Edit/documentComplete.svg";
import documentPending from "./images/New Hotel Edit/documentPending.svg";
import documentUpload from "./images/New Hotel Edit/documentDownload.svg";
import disableDocumentUpload from "./images/New Hotel Edit/disableDocumentUpload.svg";
import uploadPdfImage from "./images/New Hotel Edit/PDF_Upload.png";
import RoomIcon from "./images/Fullday inventory/Room Icon.png";
import RightArrow from "./images/Fullday inventory/Right_Arrow.svg";
import LeftArrow from "./images/Fullday inventory/Left_Arrow.svg";
import PropertyIcon from "./images/Fullday inventory/Property Icon.png";
import downArrow from "./images/Fullday inventory/down_Arrow.svg";
import blackUpArrow from "./images/Fullday inventory/blackUpArrow.svg";
import blackDownArrow from "./images/Fullday inventory/blackDownArrow.svg";
import bedLogo from "./image301.png"
import roomImage from "./image314.png"
import documentIcon from "./images/Amenity/documentIcon.svg";
import disableDocumentIcon from "./images/Amenity/disableDocumentIcon.svg";
import EditIcon from "./images/Promotions/EditIcon.svg";
import WhiteCross from "./images/Promotions/whiteCross.svg";
import redCross from "./images/systemAdmin/red cross.svg";
import profileImage from "./profileImage.png"
import noAccessImg from "./NoAccess.png"
import loginBackgroundImg from "./loginBackgroundImg.png"
import breviLogo from "./images/Profile Page/BreviLogo.svg";
import holidaLogo from "./images/Profile Page/HolidaLogo.svg";
import popularityEditIcon from "./images/Profile Page/popularityEditIcon.svg";
import TransactionIcon from "./images/Transaction/transactionIcon.svg"
import DoubleSideArrow from "./images/Transaction/doubleSideArrow.svg"
import noSearched from "./images/Transaction/noSearched.png";
import cdNotesIcon from "./images/Finance/CD Notes.svg";
import cdNotesIconClicked from "./images/Finance/CD NotesClicked.svg";

import errorPageIllus from "./images/Finance/FinanceError.png";
import invoiceIcon from "./images/Finance/Invoice.svg";
import invoiceIconClicked from "./images/Finance/InvoiceClicked.svg";

import ledgerIcon from "./images/Finance/Ledger.svg";
import ledgerIconClicked from "./images/Finance/LedgerClicked.svg";
import salesIcon from "./images/Finance/Sales.svg";
import salesIconClicked from "./images/Finance/SalesClicked.svg";
import summaryIcon from "./images/Finance/Summary.svg";
import summaryIconClicked from "./images/Finance/SummaryClicked.svg";
import AddLedgerButtonSign from "./images/Finance/AddLedgerButtonSign.svg";
import navigateIcon from "./images/PropertySearch/navigateIcon.svg";
import ratingIcon from "./images/PropertySearch/ratingIconWhite.svg";
import coupleFriendlyIcon from "./images/PropertySearch/coupleFriendlyIcon.svg";
import localIDIcon from "./images/PropertySearch/localIDIcon.svg";
import PAHIcon from "./images/PropertySearch/PAHIcon.svg";
import OptimizedIcon from "./images/PropertySearch/OptimizedIcon.svg";
import ratingIconBlack from "./images/PropertySearch/ratingIconBlack.svg";
import AdminHotelCard from "./images/PropertySearch/AdminHotelCard.png";
import couponTestImg from "./images/New Hotel Edit/couponTestImg.png"
import CouponImgGuidelines from "./images/CouponGuidelines.png"
import CouponImagePlaceholder from "./couponImgPlaceholder.png"
import rpTooltipInfoIcon from "./images/RefundPolicy/toolTioInfoIcon.svg";
import RPGreenArrow from "./images/RefundPolicy/RPGreen.svg";
import RPRedArrow from "./images/RefundPolicy/RPRed.svg";
import RPGreyArrow from "./images/RefundPolicy/RPGrey.svg";
import AddDocIcon from "./images/New Hotel Edit/imageupload.png";
import ViewPassword from "./images/Login/ViewPassword.svg";
import HidePassword from "./images/Login/HidePassword.svg";
import DisableViewPassword from "./images/Login/DisableViewPassword.svg";
import DisableHidePassword from "./images/Login/DisableHidePassword.svg";
import NearByCardImg from "./images/NearbyCardBg.png"
import Nofilter from "./images/New SEO/Nofilter.png";

const adminImages = {
    Nofilter,
    NearByCardImg,
    DisableHidePassword,
    ViewPassword,
    DisableViewPassword,
    HidePassword,
    AddDocIcon,
    couponTestImg,
    CouponImgGuidelines,
    CouponImagePlaceholder,
    AdminHotelCard,
    ratingIconBlack,
    OptimizedIcon,
    localIDIcon,
    PAHIcon,
    coupleFriendlyIcon,
    ratingIcon,
    ratesIcon,
    navigateIcon,
    RPGreenArrow,
    RPGreyArrow,
    RPRedArrow,
    rpTooltipInfoIcon,
    AddLedgerButtonSign,
    cdNotesIcon,
    cdNotesIconClicked,
    errorPageIllus,
    invoiceIcon,
    invoiceIconClicked,
    ledgerIcon,
    ledgerIconClicked,
    salesIcon,
    salesIconClicked,
    summaryIcon,
    summaryIconClicked,
    popularityEditIcon,
    breviLogo,
    holidaLogo,
    noSearched,
    DoubleSideArrow,
    TransactionIcon,
    loginBackgroundImg,
    noAccessImg,
    profileImage,
    WhiteCross,
    EditIcon,
    disableDocumentIcon,
    documentIcon,
    bedLogo,
    roomImage,
    blackUpArrow,
    blackDownArrow,
    downArrow,
    RoomIcon,
    RightArrow,
    LeftArrow,
    PropertyIcon,
    uploadPdfImage,
    disableDocumentUpload,
    documentUpload,
    documentComplete,
    documentPending,
    ImagePlaceholder,
    redCross,
    BulkPopularityImg,
    commissionIcon,
    ratesIcon,
    checkInIcon,
    quickSoldOut,
    editRooms,
    oneStar,
    twoStar,
    threeStar,
    fourStar,
    fiveStar,
    dashBoardComment,
    dashBoardNoComment,
    disableComment,
    selectLocation,
    emptyAdminImg,
    GeneralCommentIcon,
    viewHotelImg,
    changeAdminImg,
    editHotelImg,
    inventoryImg,
    welcomeImg,
    commentsImg,
    noResultsImg,
    Confirmation,
    Error,
    errorIllustration,
    successIllustration,
    cancelBooking,
    Refund,
    tick,
    CreateCoupon,
    creditCashback,
    transferBooking,
    blackCross,
    noReview,
    reviewIcon,
    emptyStar,
    filledStar,
    trialLottie,
    commentIcon,
    ErrorPage,
    saved,
    bookingChannelIcon,
    commentTabIcon,
    escIcon,
    escByIcon,
    escReasonIcon,
    escStatus,
    noComment,
};

export default adminImages;
