import { TYPE } from "../actions/types";

const initialState = {
  experiencesList: [],
  editExperiencesDetails : {},
};

export default function (state = initialState,action,response = action.payload) {
  switch (action.type) {
    case TYPE.EXPERIENCES_LIST.POST:
      state.experiencesList = response;
      return {
        ...state,
      };
    case TYPE.EDIT_EXPERIENCES_DETAILS.POST:
      state.editExperiencesDetails = response;
      return {
        ...state,
      };
    case TYPE.CREATE_PACKAGE.POST:
      return {
        ...state,
      };
    case TYPE.UPDATE_EXPERIENCES.PUT:
      return {
        ...state,
      };
    default:
      return state;
  }
}
