import { TYPE } from "../actions/types";

const initialState = {
    createRoomResponse: {},
    roomCategoryResponse: {},
    updateRoomCategoryResponse: {},
    ratePlans: [],
    updateRatePlanResponse: {},
    createRatePlanResponse: {},
    hotelAndRoomImagesResponse: {},
    roomAmenityResponse: {},
    updateRoomAmenityResponse: {},
    uploadRoomImagesResponse: {}
}

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.GET_ROOM_AMENITIES:
            state.roomAmenityResponse = response
            return { ...state, response: action.payload }

        case TYPE.ADD_REMOVE_ROOM_AMENITIES:
            state.updateRoomAmenityResponse = response
            return { ...state, response: action.payload }

        case TYPE.CREATE_ROOM:
            state.createRoomResponse = response
            return { ...state, response: action.payload }

        case TYPE.GET_ROOM_CATEGORY_DATA:
            state.roomCategoryResponse = response
            if (response.status === "SUCCESS" && response.data !== undefined) {
                state.ratePlans = response.data.rate_plan_data
            }
            return { ...state, response: action.payload }

        case TYPE.UPDATE_ROOM_CATEGORY:
            state.updateRoomCategoryResponse = response
            return { ...state, response: action.payload }

        case TYPE.GET_RATE_PLAN:
            state.ratePlansResponse = response
            return { ...state, response: action.payload }

        case TYPE.UPDATE_RATE_PLAN:
            state.updateRatePlanResponse = response
            return { ...state, response: action.payload }

        case TYPE.CREATE_RATE_PLAN:
            state.createRatePlanResponse = response
            return { ...state, response: action.payload }

        case TYPE.GET_ROOM_IMAGES:
            state.hotelAndRoomImagesResponse = response
            return { ...state, response: action.payload }
        case TYPE.UPLOAD_ROOM_CATEGORY_IMAGES:
            state.uploadRoomImagesResponse = response
            return { ...state, response: action.payload }

        case "clear_room_category_response":
            state.createRoomResponse = {}
            state.updateRoomCategoryResponse = {}
            state.roomCategoryResponse = {}
            state.ratePlans = []
            state.updateRatePlanResponse = {}
            state.createRatePlanResponse = {}
        case "clear_create_rate_plan":
            state.createRatePlanResponse = {}
        case "clear_update_room_amenity_res":
            state.updateRoomAmenityResponse = {}
        case "room_upload_image_response":
            state.uploadRoomImagesResponse = {}
        default:
            return state;
    }
}
