import { TYPE } from "../actions/types";
const initialState = {
    bucket_list:[],
    bucket_key:[],
    loading:false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case TYPE.GET_S3_BUCKETS:
      return { ...state, 
        bucket_list:action.payload.bucket_list,
        loading:false
      };
    case TYPE.GET_SPECIFIC_BUCKET:
      return { ...state, 
        bucket_list:action.payload.bucket_list,
        bucket_key:action.payload.bucket_key,
        loading:false
      };
    case TYPE.UPLOAD_BUCKET_IMAGES:
      return {
          ...state,
          updateBucketImages: action.payload,
      };

    default:
      return state;
  }
}
