import axios from "axios";

import { TYPE } from "./types";

import * as Constants from "../../constants";

export function getGeneralComment(data) {

    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const apiRoute = Constants.SUPER_ADMIN_API + '/' + TYPE.GET_GENERAL_COMMENT;
    const request = axios
      .post(apiRoute, data, { headers: { "Authorization": `Bearer ${apiToken}` } })
      .then(response => response.data);
    return {
      type: TYPE.GET_GENERAL_COMMENT,
      payload: request
    }
  }
  
  export function getPaymentComment(data) {

    let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
    const apiRoute = Constants.SUPER_ADMIN_API + '/' + TYPE.GET_PAYMENT_COMMENT;
    const request = axios
      .post(apiRoute, data, { headers: { "Authorization": `Bearer ${apiToken}` } })
      .then(response => response.data);
    return {
      type: TYPE.GET_PAYMENT_COMMENT,
      payload: request
    }
  }
  