import { TYPE } from "../actions/types";
const initialState = {
  soldOutHotelRes: {},
  analyticsRes: {}
};

export default function (
  state = initialState,
  action,
  response = action.payload
) {
  switch (action.type) {
    case TYPE.GET_SOLD_OUT_HOTELS:
      state.soldOutHotelRes = response;
      return { ...state, response: action.payload };

    case TYPE.GET_HOTELS_ANALYTICS:
      state.analyticsRes = response;
      return { ...state, response: action.payload };

    case "clear_analytics_res":
      state.analyticsRes = {};
      return { ...state, response: action.payload };

    case "clear_get_sold_out_hotels":
      state.soldOutHotelRes = {};
      return { ...state, response: action.payload };

    default:
      return state;
  }
}
