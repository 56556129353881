import React, { useRef, useState, useEffect } from "react";
import CustomHeaderComponent from "../Header_footer/Header/customHeaderComponent";
import adminImages from "../../assets";
import { Loader, Image } from "semantic-ui-react";
import HotelListingCustomDropDown from "../RegisterHotel_New/hotelListingCustomDropDown";
import CustomInputField from "./CustomInputField";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SingleDateCustomCalendar from "../SingleDateCustomCalendar";
export default function CreateEditPromotion(props) {
  const [allRoomRatePlan, setAllRoomRatePlan] = useState({
    "Deluxe Room1": [
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included1",
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included1",
    ],
    "Premium Room1": [
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included1",
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included1",
    ],
    "Deluxe Room2": [
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included2",
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included2",
    ],
    "Premium Room2": [
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included2",
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included2",
    ],
    "Deluxe Room": [
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included",
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included",
    ],
    "Premium Room": [
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included1",
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included",
    ],
  });
  const [selectedRoomRatePlan, setSelectedRoomRatePlan] = useState({
    "Deluxe Room": [
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included",
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included",
    ],
    "Premium Room": [
      "Room with free cancellation | Breakfast Included | Lunch included | Dinner Included1",
    ],
  });

  const [openStayStartDate, setOpenStayStartDate] = useState(false);
  const [openStayEndDate, setOpenStayEndDate] = useState(false);
  const [openBookingStartDate, setOpenBookingStartDate] = useState(false);
  const [openBookingEndDate, setOpenBookingEndDate] = useState(false);

  const [showCalendar, setShowCalender] = useState(false);
  const [isEditEnable, setIsEditEnable] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);
  const [promotionType, setPromotionType] = useState(null);
  const [promotionTypeDD, setPromotionTypeDD] = useState(false);
  let promotionTypeDDRef = useRef();
  const [offerType, setOfferType] = useState(null);
  const [offerTypeDD, setOfferTypeDD] = useState(false);
  let offerTypeDDRef = useRef();

  const [discountForAll, setDiscountForAll] = useState(null);
  const [discountForLoggedIn, setDiscountForLoggedIn] = useState(null);
  const [noOfNightBookingAll, setNoOfNightBookingAll] = useState(null);
  const [noOfNightFreeAll, setNoOfNightFreeAll] = useState(null);

  const [noOfNightBookingLoggedIn, setNoOfNightBookingLoggedIn] =
    useState(null);
  const [noOfNightFreeLoggedIn, setNoOfNightFreeLoggedIn] = useState(null);

  const [isSetMaxLengthStay, setIsSetMaxLengthStay] = useState(null);
  const [isSetMaxLengthStayDD, setIsSetMaxLengthStayDD] = useState(false);
  let isSetMaxLengthStayDDRef = useRef();
  const [isSetMinLengthStay, setIsSetMinLengthStay] = useState(null);
  const [isSetMinLengthStayDD, setIsSetMinLengthStayDD] = useState(false);
  let isSetMinLengthStayDDRef = useRef();
  const [maxStayDuration, setMaxStayDuration] = useState(null);
  const [maxStayDurationDD, setMaxStayDurationDD] = useState(false);
  let maxStayDurationDDRef = useRef();
  const [minStayDuration, setMinStayDuration] = useState(null);
  const [minStayDurationDD, setMinStayDurationDD] = useState(false);
  let minStayDurationDDRef = useRef();
  const [earlyBirdRule, setEarlyBirdRule] = useState(null);
  const [earlyBirdRuleDD, setEarlyBirdRuleDD] = useState(false);
  let earlyBirdRuleDDref = useRef();
  const [earlyBirdBookingWindow, setEarlyBirdBookingWindow] = useState(null);
  const [earlyBirdBookingWindowDD, setEarlyBirdBookingWindowDD] =
    useState(false);
  let earlyBirdBookingWindowDDRef = useRef();

  const [lastMinDealRule, setlastMinDealRule] = useState(null);
  const [lastMinDealRuleDD, setlastMinDealRuleDD] = useState(false);
  let lastMinDealRuleDDRef = useRef();

  const [lastMinDealBookingWindow, setlastMinDealBookingWindow] =
    useState(null);

  const [lastMinDealBookingWindowDD, setlastMinDealBookingWindowDD] =
    useState(false);
  let lastMinDealBookingWindowDDRef = useRef();

  const [isDayBasedApplicability, setIsDayBasedApplicabilty] = useState(null);
  const [isDayBasedApplicabilityDD, setIsDayBasedApplicabiltyDD] =
    useState(false);
  let isDayBasedApplicabilityDDRef = useRef();

  const [applicableDays, setApplicableDays] = useState(null);
  const [applicableFor, setApplicableFor] = useState(null);
  const [applicableForDD, setApplicableForDD] = useState(false);
  let applicableForDDRef = useRef();

  const [stayStartDate, setStayStartDate] = useState(null);
  const [stayEndDate, setStayEndDate] = useState(null);
  const [bookingStartDate, setBookingStartDate] = useState(null);
  const [bookingEndDate, setBookingEndDate] = useState(null);
  const [isBackoutDates, setIsBackoutDates] = useState(null);
  const [isBackoutDatesDD, setIsBackoutDatesDD] = useState(false);
  let isBackoutDatesDDRef = useRef();

  const [ratePlanApplicability, setRatePlanApplicability] = useState(null);
  const [ratePlanApplicabilityDD, setRatePlanApplicabilityDD] = useState(false);
  let ratePlanApplicabilityDDRef = useRef();

  const [selectedBackOutDays, setSelectedBackoutDays] = useState([
    "2023-08-18",
    "2023-08-19",
    "2023-08-20",
    "2023-08-21",
    "2023-08-22",
  ]);
  const [refundability, setRefundability] = useState(null);
  const [refundabilityDD, setRefundabilityDD] = useState(false);
  let refundabilityDDRef = useRef();
  const [expiryDate, setExpiryDate] = useState(null);
  const [openExpiryDate, setOpenExpiryDate] = useState(null);

  const [promotionName, setPromotionName] = useState(null);
  const [stayEndVisible, setStayEndDateVisible] = useState(false);
  const [bookingEndDateVisible, setBookingEndDateVisible] = useState(false);

  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const promotionTypeOptions = [
    {
      key: "Discount",
      text: "Discount",
      value: "Discount",
    },
    {
      key: "Night Free",
      text: "Night Free",
      value: "Night Free",
    },
  ];

  const offerTypeOptions = [
    { key: "Amount", text: "Amount", value: "Amount" },
    { key: "Percentage", text: "Percentage", value: "Percentage" },
  ];

  const yesNoOptions = [
    { key: "Yes", text: "Yes", value: 1 },
    { key: "No", text: "No", value: 0 },
  ];

  const daysOption = [];

  for (let i = 1; i <= 30; i++) {
    const dayNumber = i < 10 ? `0${i}` : `${i}`;
    const dayObject = {
      key: `${dayNumber} Days`,
      text: `${dayNumber} Days`,
      value: dayNumber,
    };
    daysOption.push(dayObject);
  }

  const applicableForOptions = [
    { text: "Stay Date", key: "Stay Date", value: "Stay Date" },
    { text: "Booking Date", key: "Booking Date", value: "Booking Date" },
    {
      text: "Stay Date & Booking Date",
      key: "Stay Date & Booking Date",
      value: "Stay Date & Booking Date",
    },
  ];

  const applicabilityOptions = [
    {
      text: "Selected Rate Plans",
      value: "Selected Rate Plans",
      key: "Selected Rate Plans",
    },
    { text: "All Rate Plan", value: "All Rate Plan", key: "All Rate Plan" },
  ];
  useEffect(() => {
    let handler = (e) => {
      if (promotionTypeDD) {
        if (!promotionTypeDDRef.current.contains(e.target)) {
          setPromotionTypeDD(false);
        }
      } else if (offerTypeDD) {
        if (!offerTypeDDRef.current.contains(e.target)) {
          setOfferTypeDD(false);
        }
      } else if (isSetMaxLengthStayDD) {
        if (!isSetMaxLengthStayDDRef.current.contains(e.target)) {
          setIsSetMaxLengthStayDD(false);
        }
      } else if (maxStayDurationDD) {
        if (!maxStayDurationDDRef.current.contains(e.target)) {
          setMaxStayDurationDD(false);
        }
      } else if (isSetMinLengthStayDD) {
        if (!isSetMinLengthStayDDRef.current.contains(e.target)) {
          setIsSetMinLengthStayDD(false);
        }
      } else if (minStayDurationDD) {
        if (!minStayDurationDDRef.current.contains(e.target)) {
          setMinStayDurationDD(false);
        }
      } else if (isSetMinLengthStayDD) {
        if (!isSetMinLengthStayDDRef.current.contains(e.target)) {
          setIsSetMinLengthStayDD(false);
        }
      } else if (minStayDurationDD) {
        if (!minStayDurationDDRef.current.contains(e.target)) {
          setMinStayDurationDD(false);
        }
      } else if (earlyBirdRuleDD) {
        if (!earlyBirdRuleDDref.current.contains(e.target)) {
          setEarlyBirdRuleDD(false);
        }
      } else if (earlyBirdBookingWindowDD) {
        if (!earlyBirdBookingWindowDDRef.current.contains(e.target)) {
          setEarlyBirdBookingWindowDD(false);
        }
      } else if (lastMinDealRuleDD) {
        if (!lastMinDealRuleDDRef.current.contains(e.target)) {
          setlastMinDealRuleDD(false);
        }
      } else if (lastMinDealBookingWindowDD) {
        if (!lastMinDealBookingWindowDDRef.current.contains(e.target)) {
          setlastMinDealBookingWindowDD(false);
        }
      } else if (isDayBasedApplicabilityDD) {
        if (!isDayBasedApplicabilityDDRef.current.contains(e.target)) {
          setIsDayBasedApplicabiltyDD(false);
        }
      } else if (applicableForDD) {
        if (!applicableForDDRef.current.contains(e.target)) {
          setApplicableForDD(false);
        }
      } else if (isBackoutDatesDD) {
        if (!isBackoutDatesDDRef.current.contains(e.target)) {
          setIsBackoutDatesDD(false);
        }
      } else if (refundabilityDD) {
        if (!refundabilityDDRef.current.contains(e.target)) {
          setRefundabilityDD(false);
        }
      } else if (ratePlanApplicabilityDD) {
        if (!ratePlanApplicabilityDDRef.current.contains(e.target)) {
          setRatePlanApplicabilityDD(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // const isEqual = (apiValue, inputValue, key) => {
  //   if (key == "hotelDescription") {
  //     if (String(inputValue).toLowerCase() === String(apiValue).toLowerCase()) {
  //       const updatedState = {
  //         ...updateFieldObj,
  //         [key]: false,
  //       };
  //       setUpdateFieldObj(updatedState);
  //     } else {
  //       const updatedState = {
  //         ...updateFieldObj,
  //         [key]: true,
  //       };
  //       setUpdateFieldObj(updatedState);
  //     }
  //   } else {
  //     if (inputValue == apiValue) {
  //       const updatedState = {
  //         ...updateFieldObj,
  //         [key]: false,
  //       };
  //       setUpdateFieldObj(updatedState);
  //     } else {
  //       const updatedState = {
  //         ...updateFieldObj,
  //         [key]: true,
  //       };
  //       setUpdateFieldObj(updatedState);
  //     }
  //   }
  // };

  const handleInputChange = (e, inputFor) => {
    if (e.target.value.length === 0 || /^[0-9]+$/.test(e.target.value)) {
      if (inputFor == "Discount for all users") {
        if (offerType == "Percentage") {
          if (e.target.value <= 100) {
            setDiscountForAll(e.target.value);
          }
        } else {
          setDiscountForAll(e.target.value);
        }
      }

      if (inputFor == "Discount for logged in user") {
        if (offerType == "Percentage") {
          if (e.target.value <= 100) {
            setDiscountForLoggedIn(e.target.value);
          }
        } else {
          setDiscountForLoggedIn(e.target.value);
        }
      }

      if (inputFor == "Discount for logged in user booked") {
        setNoOfNightBookingLoggedIn(e.target.value);
      }
      if (inputFor == "Discount for logged in user free") {
        setNoOfNightFreeLoggedIn(e.target.value);
      }

      if (inputFor == "Discount for all user booked") {
        setNoOfNightBookingAll(e.target.value);
      }
      if (inputFor == "Discount for all user free") {
        setNoOfNightFreeAll(e.target.value);
      }
    }
    if (inputFor == "Promotion Name") {
      setPromotionName(e.target.value);
    }
  };

  const refundabiltyOptions = [
    { key: "Refundable", text: "Refundable", value: "Refundable" },
    { key: "Non-Refundable", text: "Non-Refundable", value: "Non-Refundable" },
  ];

  const onHandleDropDown = (type) => {
    console.log("Type", type);
    if (type == "Promotion Type") {
      setPromotionTypeDD(!promotionTypeDD);
    } else if (type == "Offer Type") {
      setOfferTypeDD(!offerTypeDD);
    } else if (type == "Set Max Length of Stay") {
      setIsSetMaxLengthStayDD(!isSetMaxLengthStayDD);
    } else if (type == "Max Stay Duration for Discount") {
      setMaxStayDurationDD(!maxStayDurationDD);
    } else if (type == "Set Min Length of Stay") {
      setIsSetMinLengthStayDD(!isSetMinLengthStayDD);
    } else if (type == "Min Stay Duration for Discount") {
      setMinStayDurationDD(!minStayDurationDD);
    } else if (type == "Set Early Bird Rule") {
      setEarlyBirdRuleDD(!earlyBirdRuleDD);
    } else if (type == "Early Bird Booking Window") {
      setEarlyBirdBookingWindowDD(!earlyBirdBookingWindowDD);
    } else if (type == "Set Last Minute Deal Rule") {
      setlastMinDealRuleDD(!lastMinDealRuleDD);
    } else if (type == "Last Minute Booking Window") {
      setlastMinDealBookingWindowDD(!lastMinDealBookingWindowDD);
    } else if (type == "Set Day Based Applicability") {
      setIsDayBasedApplicabiltyDD(!isDayBasedApplicabilityDD);
    } else if (type == "Applicable For") {
      setApplicableForDD(!applicableForDD);
    } else if (type == "Set Blackout Stay Dates") {
      setIsBackoutDatesDD(!isBackoutDatesDD);
    } else if (type == "Set Refundability") {
      setRefundabilityDD(!refundabilityDD);
    } else if (type == "Rate Plan Applicability") {
      setRatePlanApplicabilityDD(!ratePlanApplicabilityDD);
    }
  };

  const onSelectDropDownOption = (type, option) => {
    if (type == "Promotion Type") {
      setPromotionType(option);
      setPromotionTypeDD(false);
    } else if (type == "Offer Type") {
      setOfferType(option);
      setOfferTypeDD(false);
    } else if (type == "Set Max Length of Stay") {
      setIsSetMaxLengthStay(option);
      setIsSetMaxLengthStayDD(false);
    } else if (type == "Max Stay Duration for Discount") {
      setMaxStayDuration(option);
      setMaxStayDurationDD(false);
    } else if (type == "Set Min Length of Stay") {
      setIsSetMinLengthStay(option);
      setIsSetMinLengthStayDD(false);
    } else if (type == "Min Stay Duration for Discount") {
      setMinStayDuration(option);
      setMinStayDurationDD(false);
    } else if (type == "Set Early Bird Rule") {
      setEarlyBirdRule(option);
      if (option.value == 1) {
        setlastMinDealRule({ key: "No", text: "No", value: 0 });
      }
      setEarlyBirdRuleDD(false);
    } else if (type == "Early Bird Booking Window") {
      setEarlyBirdBookingWindow(option);
      setEarlyBirdBookingWindowDD(false);
    } else if (type == "Set Last Minute Deal Rule") {
      setlastMinDealRule(option);
      if (option.value == 1) {
        setEarlyBirdRule({ key: "No", text: "No", value: 0 });
      }
      setlastMinDealRuleDD(false);
    } else if (type == "Last Minute Booking Window") {
      setlastMinDealBookingWindow(option);
      setlastMinDealBookingWindowDD(false);
    } else if (type == "Set Day Based Applicability") {
      setIsDayBasedApplicabilty(option);
      setIsDayBasedApplicabiltyDD(false);
    } else if (type == "Applicable For") {
      setApplicableFor(option);
      setApplicableForDD(false);
    } else if (type == "Set Blackout Stay Dates") {
      setIsBackoutDates(option);
      setIsBackoutDatesDD(false);
    } else if (type == "Set Refundability") {
      setRefundability(option);
      setRefundabilityDD(false);
    } else if (type == "Rate Plan Applicability") {
      setRatePlanApplicability(option);
      setRatePlanApplicabilityDD(false);
    }
    // isEqual(hotelData.hotel_category, option.value, "hotelCategory");
  };

  const checkNullUndefined = (value) => {
    return value != null && value != undefined;
  };

  const addDays = (day) => {
    if (checkNullUndefined(applicableDays)) {
      if (applicableDays.length == 0) {
        setApplicableDays([day]);
      } else {
        if (applicableDays.includes(day)) {
          const updatedItems = applicableDays.filter(
            (item, index) => item !== day
          );
          setApplicableDays(updatedItems);
        } else {
          setApplicableDays([day, ...applicableDays]);
        }
      }
    } else {
      setApplicableDays([day]);
    }
  };

  const handleToggle = (type) => {
    if (type == "Stay Date") {
      setStayEndDateVisible(!stayEndVisible);
    } else {
      setBookingEndDateVisible(!bookingEndDateVisible);
    }
  };

  const removeDate = (date) => {
    const arr = selectedBackOutDays.filter((item) => item != date);
    setSelectedBackoutDays(arr);
  };

  
  const onChangeStayStartDate = (se, event) => {
    const date = se.format("DD/MM/YY");
    setStayStartDate(date);
  };

  const onChangeStayEndDate = (se, event) => {
    const date = se.format("DD/MM/YY");
    setStayEndDate(date);
  };

  const onChangeBookingStartDate = (se, event) => {
    const date = se.format("DD/MM/YY");
    setBookingStartDate(date);
  };

  const onChangeBookingEndDate = (se, event) => {
    const date = se.format("DD/MM/YY");
    setBookingEndDate(date);
  };

  const onChangeExpiryDate = (se, event) => {
    const date = se.format("DD/MM/YY");
    setExpiryDate(date);
  };

  return (
    <div
      className="hl_cls"
      style={{
        backgroundColor: "#f1f1f1",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="hl_header_cls">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CustomHeaderComponent {...props} screenName="Custom Promotion" />
        </div>
        <div></div>
      </div>

      {dataLoading ? (
        <div
          style={{
            height: "60vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Loader active inline="centered" />
          <p
            style={{
              marginTop: 10,
              textAlign: "center",
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#818181",
              fontSize: "1rem",
            }}
          >
            Loading Results...
          </p>
        </div>
      ) : isErrorOccurred ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img src={adminImages.noResultsImg} className="no_results_img" />
          <p
            style={{
              marginTop: 10,
              textAlign: "center",
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#818181",
              fontSize: "1rem",
            }}
          >
            Some Error Occured
          </p>
        </div>
      ) : (
        <div className="custom-promotion-outer-container">
          <div className="custom-promotion-inner-container">
            {/* Step 1: Offer Type */}
            <div className="cp_module_container">
              <p className="custom-promotion-module-heading">
                Step 1: Offer Type
              </p>

              <HotelListingCustomDropDown
                label={"Promotion Type *"}
                placeholder="Select Promotion Type"
                dropdownStatus={promotionTypeDD}
                onHandleDropDown={onHandleDropDown}
                dropdownOptions={promotionTypeOptions}
                onSelectDropDownOption={onSelectDropDownOption}
                dropdownValue={promotionType == null ? "" : promotionType}
                dropDownFor="Promotion Type"
                dropDownRef={promotionTypeDDRef}
                // isUpdated={updateFieldObj.hotelCategory}
              />

              {checkNullUndefined(promotionType) ? (
                promotionType.value == "Discount" ? (
                  <div className="cp_grey_box">
                    <HotelListingCustomDropDown
                      label={"Offer Type *"}
                      placeholder="Select Offer Type"
                      dropdownStatus={offerTypeDD}
                      onHandleDropDown={onHandleDropDown}
                      dropdownOptions={offerTypeOptions}
                      onSelectDropDownOption={onSelectDropDownOption}
                      dropdownValue={offerType == null ? "" : offerType}
                      dropDownFor="Offer Type"
                      dropDownRef={offerTypeDDRef}
                      // isUpdated={updateFieldObj.hotelCategory}
                    />
                    {offerType != null &&
                      offerType != undefined &&
                      offerType.value != "" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.25rem",
                          }}
                        >
                          <div>
                            <p className="hl_feilds_lable_cls">
                              Discount for all users *
                            </p>

                            <CustomInputField
                              type={offerType.value == "Percentage" ? "%" : "₹"}
                              value={discountForAll}
                              className={"all-user-discount-field"}
                              handleInputChange={handleInputChange}
                              placeholder={"Enter Discount Value"}
                              label={"Discount for all users *"}
                              inputFor={"Discount for all users"}
                            />
                          </div>
                          <div>
                            <div>
                              <p className="hl_feilds_lable_cls">
                                Discount for logged in user *
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "0.25rem",
                                }}
                              >
                                <div
                                  className={`all-user-discount-box ${
                                    discountForAll == null ||
                                    discountForAll == null
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  <p
                                    className="p_text p_text_sm p_text_bold"
                                    style={{ color: "#818181" }}
                                  >
                                    {discountForAll}
                                  </p>
                                </div>
                                <p
                                  className="p_text p_text_sm p_text_bold"
                                  style={{ color: "#818181" }}
                                >
                                  +
                                </p>
                                <CustomInputField
                                  type={
                                    offerType.value == "Percentage" ? "%" : "₹"
                                  }
                                  value={discountForLoggedIn}
                                  className={"logged-in-user-discount-field"}
                                  handleInputChange={handleInputChange}
                                  placeholder={""}
                                  label={"Discount for logged in user *"}
                                  inputFor={"Discount for logged in user"}
                                  isDisable={
                                    discountForAll == null ||
                                    discountForAll == null
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}{" "}
                  </div>
                ) : (
                  <div
                    className="cp_grey_box"
                    style={{
                      display: "flex",
                      gap: "1.5rem",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <p className="hl_feilds_lable_cls">
                        Discount for logged in user *
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <p
                          className="p_text p_text_sm p_text_bold"
                          style={{ color: "#818181" }}
                        >
                          Back
                        </p>
                        <CustomInputField
                          value={noOfNightBookingLoggedIn}
                          className={"input-box"}
                          handleInputChange={handleInputChange}
                          placeholder={""}
                          inputFor={"Discount for logged in user booked"}
                          isDisable={false}
                        />
                        <p
                          className="p_text p_text_sm p_text_bold"
                          style={{ color: "#818181" }}
                        >
                          nights and get
                        </p>
                        <CustomInputField
                          value={noOfNightFreeLoggedIn}
                          className={"input-box"}
                          handleInputChange={handleInputChange}
                          placeholder={""}
                          inputFor={"Discount for logged in user free"}
                          isDisable={false}
                        />
                        <p
                          className="p_text p_text_sm p_text_bold"
                          style={{ color: "#818181" }}
                        >
                          free
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="hl_feilds_lable_cls">
                        Discount for all user *
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <p
                          className="p_text p_text_sm p_text_bold"
                          style={{ color: "#818181" }}
                        >
                          Back
                        </p>
                        <CustomInputField
                          value={noOfNightBookingAll}
                          className={"input-box"}
                          handleInputChange={handleInputChange}
                          placeholder={""}
                          inputFor={"Discount for all user booked"}
                          isDisable={false}
                        />
                        <p
                          className="p_text p_text_sm p_text_bold"
                          style={{ color: "#818181" }}
                        >
                          nights and get
                        </p>
                        <CustomInputField
                          value={noOfNightFreeAll}
                          className={"input-box"}
                          handleInputChange={handleInputChange}
                          placeholder={""}
                          inputFor={"Discount for all user free"}
                          isDisable={false}
                        />
                        <p
                          className="p_text p_text_sm p_text_bold"
                          style={{ color: "#818181" }}
                        >
                          free
                        </p>
                      </div>
                    </div>
                  </div>
                )
              ) : null}
            </div>

            {/* Step 2: Offer Restrictions */}
            <div className="cp_module_container">
              <p className="custom-promotion-module-heading">
                Step 2: Offer Restrictions
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.25rem",
                }}
              >
                <HotelListingCustomDropDown
                  label={"Set Max Length of Stay *"}
                  placeholder="Select Option"
                  dropdownStatus={isSetMaxLengthStayDD}
                  onHandleDropDown={onHandleDropDown}
                  dropdownOptions={yesNoOptions}
                  onSelectDropDownOption={onSelectDropDownOption}
                  dropdownValue={
                    isSetMaxLengthStay == null ? "" : isSetMaxLengthStay
                  }
                  dropDownFor="Set Max Length of Stay"
                  dropDownRef={isSetMaxLengthStayDDRef}
                  // isUpdated={updateFieldObj.hotelCategory}
                />

                {checkNullUndefined(isSetMaxLengthStay) &&
                isSetMaxLengthStay.value == 1 ? (
                  <HotelListingCustomDropDown
                    label={"Max Stay Duration for Discount *"}
                    placeholder="Select Option"
                    dropdownStatus={maxStayDurationDD}
                    onHandleDropDown={onHandleDropDown}
                    dropdownOptions={daysOption}
                    onSelectDropDownOption={onSelectDropDownOption}
                    dropdownValue={
                      maxStayDuration == null ? "" : maxStayDuration
                    }
                    dropDownFor="Max Stay Duration for Discount"
                    dropDownRef={maxStayDurationDDRef}
                    // isUpdated={updateFieldObj.hotelCategory}
                  />
                ) : null}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.25rem",
                }}
              >
                <HotelListingCustomDropDown
                  label={"Set Min Length of Stay *"}
                  placeholder="Select Option"
                  dropdownStatus={isSetMinLengthStayDD}
                  onHandleDropDown={onHandleDropDown}
                  dropdownOptions={yesNoOptions}
                  onSelectDropDownOption={onSelectDropDownOption}
                  dropdownValue={
                    isSetMinLengthStay == null ? "" : isSetMinLengthStay
                  }
                  dropDownFor="Set Min Length of Stay"
                  dropDownRef={isSetMinLengthStayDDRef}
                  // isUpdated={updateFieldObj.hotelCategory}
                />

                {checkNullUndefined(isSetMinLengthStay) &&
                isSetMinLengthStay.value == 1 ? (
                  <HotelListingCustomDropDown
                    label={"Min Stay Duration for Discount *"}
                    placeholder="Select Option"
                    dropdownStatus={minStayDurationDD}
                    onHandleDropDown={onHandleDropDown}
                    dropdownOptions={daysOption}
                    onSelectDropDownOption={onSelectDropDownOption}
                    dropdownValue={
                      minStayDuration == null ? "" : minStayDuration
                    }
                    dropDownFor="Min Stay Duration for Discount"
                    dropDownRef={minStayDurationDDRef}
                    // isUpdated={updateFieldObj.hotelCategory}
                  />
                ) : null}
              </div>
            </div>

            {/* Step 3:  Booking Window */}
            <div className="cp_module_container">
              <p className="custom-promotion-module-heading">
                Step 3: Booking Window
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.25rem",
                }}
              >
                <HotelListingCustomDropDown
                  label={"Set Early Bird Rule *"}
                  placeholder="Select Option"
                  dropdownStatus={earlyBirdRuleDD}
                  onHandleDropDown={onHandleDropDown}
                  dropdownOptions={yesNoOptions}
                  onSelectDropDownOption={onSelectDropDownOption}
                  dropdownValue={earlyBirdRule == null ? "" : earlyBirdRule}
                  dropDownFor="Set Early Bird Rule"
                  dropDownRef={earlyBirdRuleDDref}
                  // isUpdated={updateFieldObj.hotelCategory}
                />

                {checkNullUndefined(earlyBirdRule) &&
                earlyBirdRule.value == 1 ? (
                  <HotelListingCustomDropDown
                    label={"Early Bird Booking Window *"}
                    placeholder="Select Option"
                    dropdownStatus={earlyBirdBookingWindowDD}
                    onHandleDropDown={onHandleDropDown}
                    dropdownOptions={daysOption}
                    onSelectDropDownOption={onSelectDropDownOption}
                    dropdownValue={
                      earlyBirdBookingWindow == null
                        ? ""
                        : earlyBirdBookingWindow
                    }
                    dropDownFor="Early Bird Booking Window"
                    dropDownRef={earlyBirdBookingWindowDDRef}
                    // isUpdated={updateFieldObj.hotelCategory}
                  />
                ) : null}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.25rem",
                }}
              >
                <HotelListingCustomDropDown
                  label={"Set Last Minute Deal Rule *"}
                  placeholder="Select Option"
                  dropdownStatus={lastMinDealRuleDD}
                  onHandleDropDown={onHandleDropDown}
                  dropdownOptions={yesNoOptions}
                  onSelectDropDownOption={onSelectDropDownOption}
                  dropdownValue={lastMinDealRule == null ? "" : lastMinDealRule}
                  dropDownFor="Set Last Minute Deal Rule"
                  dropDownRef={lastMinDealRuleDDRef}
                  // isUpdated={updateFieldObj.hotelCategory}
                />

                {checkNullUndefined(lastMinDealRule) &&
                lastMinDealRule.value == 1 ? (
                  <HotelListingCustomDropDown
                    label={"Last Minute Booking Window *"}
                    placeholder="Select Option"
                    dropdownStatus={lastMinDealBookingWindowDD}
                    onHandleDropDown={onHandleDropDown}
                    dropdownOptions={daysOption}
                    onSelectDropDownOption={onSelectDropDownOption}
                    dropdownValue={
                      lastMinDealBookingWindow == null
                        ? ""
                        : lastMinDealBookingWindow
                    }
                    dropDownFor="Last Minute Booking Window"
                    dropDownRef={lastMinDealBookingWindowDDRef}
                    // isUpdated={updateFieldObj.hotelCategory}
                  />
                ) : null}
              </div>
            </div>

            {/* Step 4:  Day Based Applicability */}
            <div className="cp_module_container">
              <p className="custom-promotion-module-heading">
                Step 4: Day Based Applicability
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.25rem",
                }}
              >
                <HotelListingCustomDropDown
                  label={"Set Day Based Applicability *"}
                  placeholder="Select Option"
                  dropdownStatus={isDayBasedApplicabilityDD}
                  onHandleDropDown={onHandleDropDown}
                  dropdownOptions={yesNoOptions}
                  onSelectDropDownOption={onSelectDropDownOption}
                  dropdownValue={
                    isDayBasedApplicability == null
                      ? ""
                      : isDayBasedApplicability
                  }
                  dropDownFor="Set Day Based Applicability"
                  dropDownRef={isDayBasedApplicabilityDDRef}
                  // isUpdated={updateFieldObj.hotelCategory}
                />

                {checkNullUndefined(isDayBasedApplicability) &&
                isDayBasedApplicability.value == 1 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    {weekDays.map((day) => (
                      <div
                        onClick={() => addDays(day)}
                        className={`weekDayBox ${
                          checkNullUndefined(applicableDays) &&
                          applicableDays.includes(day)
                            ? "selected"
                            : ""
                        }`}
                      >
                        <p
                          className="p_text p_text_sm p_text_bold"
                          style={{
                            color:
                              checkNullUndefined(applicableDays) &&
                              applicableDays.includes(day)
                                ? "#fff"
                                : "",
                          }}
                        >
                          {day}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>

            {/* Step 5:  Promotion Dates */}
            <div className="cp_module_container">
              <p className="custom-promotion-module-heading">
                Step 5: Promotion Dates
              </p>

              {isEditEnable ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <HotelListingCustomDropDown
                    label={"Applicable For"}
                    placeholder="Select Option"
                    dropdownStatus={applicableForDD}
                    onHandleDropDown={onHandleDropDown}
                    dropdownOptions={applicableForOptions}
                    onSelectDropDownOption={onSelectDropDownOption}
                    dropdownValue={applicableFor == null ? "" : applicableFor}
                    dropDownFor="Applicable For"
                    dropDownRef={applicableForDDRef}
                    // isUpdated={updateFieldObj.hotelCategory}
                  />
                  {checkNullUndefined(applicableFor) &&
                    applicableFor.value != "" && (
                      <div className="cp_grey_box promotion_date">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2rem",
                          }}
                        >
                          {applicableFor.value != "Booking Date" && (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <p className="p_text p_text_md p_text_bold">
                                  Stay Date
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.4rem",
                                  }}
                                >
                                  <div
                                    onClick={() => handleToggle("Stay Date")}
                                    className={`toggle_switch_outer ${
                                      stayEndVisible ? "open" : ""
                                    }`}
                                    style={{
                                      marginLeft: "1.5rem",
                                      marginRight: "0.6rem",
                                    }}
                                  >
                                    <div
                                      className={`toggle_switch_inner ${
                                        stayEndVisible ? "active" : ""
                                      }`}
                                    />
                                  </div>
                                  <p className="p_text p_text_sm p_text_bold">
                                    End Date
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginTop: "2rem",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1.25rem",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "0.75rem",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="lease_expiry_calender">
                                    <div style={{ marginBottom: "0.75rem" }}>
                                      <p className="hl_feilds_lable_cls">
                                        Start Date
                                      </p>
                                    </div>
                                    <DatePicker
                                      selected={
                                        stayStartDate == null
                                          ? ""
                                          : moment(stayStartDate)
                                      }
                                      dateFormat="ll"
                                      placeholderText="DD/MM/YY"
                                      onChange={(se, event) =>
                                        onChangeStayStartDate(
                                          se,
                                          event,
                                        )
                                      }
                                    />
                                  </div>
                                  {/* <SingleDateCustomCalendar
                                    open={openStayStartDate}
                                    setOpen={setOpenStayStartDate}
                                    showCalendar={showCalendar}
                                    setShowCalendar={setShowCalender}
                                    date={stayStartDate}
                                    setDate={setStayStartDate}
                                    type="Start Date"
                                    restrictedPastDate={moment()}
                                    isLoading={false}
                                  /> */}
                                </div>

                                {stayEndVisible && (
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "0.75rem",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div className="lease_expiry_calender">
                                      <div style={{ marginBottom: "0.75rem" }}>
                                        <p className="hl_feilds_lable_cls">
                                          End Date
                                        </p>
                                      </div>
                                      <DatePicker
                                        selected={
                                          stayEndDate == null
                                            ? ""
                                            : moment(stayEndDate)
                                        }
                                        dateFormat="ll"
                                        placeholderText="DD/MM/YY"
                                        onChange={(se, event) =>
                                          onChangeStayEndDate(
                                            se,
                                            event,
                                          )
                                        }
                                      />
                                    </div>
                                    {/* <SingleDateCustomCalendar
                                      open={openStayEndDate}
                                      setOpen={setOpenStayEndDate}
                                      showCalendar={showCalendar}
                                      setShowCalendar={setShowCalender}
                                      date={stayEndDate}
                                      setDate={setStayEndDate}
                                      type="End Date"
                                      restrictedPastDate={moment()}
                                      isLoading={false}
                                    /> */}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {applicableFor.value != "Stay Date" && (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <p className="p_text p_text_md p_text_bold">
                                  Booking Date
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.4rem",
                                  }}
                                >
                                  <div
                                    onClick={() => handleToggle("Booking Date")}
                                    className={`toggle_switch_outer ${
                                      bookingEndDateVisible ? "open" : ""
                                    }`}
                                    style={{
                                      marginLeft: "1.5rem",
                                      marginRight: "0.6rem",
                                    }}
                                  >
                                    <div
                                      className={`toggle_switch_inner ${
                                        bookingEndDateVisible ? "active" : ""
                                      }`}
                                    />
                                  </div>
                                  <p className="p_text p_text_sm p_text_bold">
                                    End Date
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginTop: "2rem",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1.25rem",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "0.75rem",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="lease_expiry_calender">
                                    <div style={{ marginBottom: "0.75rem" }}>
                                      <p className="hl_feilds_lable_cls">
                                        Start Date
                                      </p>
                                    </div>
                                    <DatePicker
                                      selected={
                                        bookingStartDate == null
                                          ? ""
                                          : moment(bookingStartDate)
                                      }
                                      dateFormat="ll"
                                      placeholderText="DD/MM/YY"
                                      onChange={(se, event) =>
                                        onChangeBookingStartDate(
                                          se,
                                          event,
                                        )
                                      }
                                    />
                                  </div>
                                  {/* <SingleDateCustomCalendar
                                    open={openBookingStartDate}
                                    setOpen={setOpenBookingStartDate}
                                    showCalendar={showCalendar}
                                    setShowCalendar={setShowCalender}
                                    date={bookingStartDate}
                                    setDate={setBookingStartDate}
                                    type="Start Date"
                                    restrictedPastDate={moment()}
                                    isLoading={false}
                                  /> */}
                                </div>

                                {bookingEndDateVisible && (
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "0.75rem",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div className="lease_expiry_calender">
                                      <div style={{ marginBottom: "0.75rem" }}>
                                        <p className="hl_feilds_lable_cls">
                                          End Date
                                        </p>
                                      </div>
                                      <DatePicker
                                        selected={
                                          bookingEndDate == null
                                            ? ""
                                            : moment(bookingEndDate)
                                        }
                                        dateFormat="ll"
                                        placeholderText="DD/MM/YY"
                                        onChange={(se, event) =>
                                          onChangeBookingEndDate(
                                            se,
                                            event,
                                          )
                                        }
                                      />
                                    </div>
                                    {/* <SingleDateCustomCalendar
                                      open={openBookingEndDate}
                                      setOpen={setOpenBookingEndDate}
                                      showCalendar={showCalendar}
                                      setShowCalendar={setShowCalender}
                                      date={bookingEndDate}
                                      setDate={setBookingEndDate}
                                      type="End Date"
                                      restrictedPastDate={moment()}
                                      isLoading={false}
                                    /> */}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                </div>
              ) : (
                <div className="promotion-box">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20ZM11 5H9V7H11V5ZM11 9H9V15H11V9Z"
                      fill="#7DBF66"
                    />
                  </svg>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      flexDirection: "column",
                      gap: "1.5rem",
                    }}
                  >
                    <p className="p_text p_text_md p_text_regular">
                      This promotion will be active from the{" "}
                      <span style={{ fontWeight: "600" }}>Creation Date</span>{" "}
                      and has{" "}
                      <span style={{ fontWeight: "600" }}>No end Date</span>
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.75rem",
                      }}
                    >
                      <p className="p_text p_text_sm p_text_regular">
                        Edit dates to change offer applicabilityEdit dates to
                        change offer applicability
                      </p>
                      <button
                        className="cp-edit-btn"
                        onClick={() => setIsEditEnable(true)}
                      >
                        <Image src={adminImages.EditIcon} />
                        <p
                          className="p_text p_text_md p_text_bold"
                          style={{ color: "white", cursor: "pointer" }}
                        >
                          Edit
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Step 6:  Promotion Settings */}
            <div className="cp_module_container">
              <p className="custom-promotion-module-heading">
                Step 6: Promotion Settings
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.25rem",
                }}
              >
                <HotelListingCustomDropDown
                  label={"Set Blackout Stay Dates *"}
                  placeholder="Select Option"
                  dropdownStatus={isBackoutDatesDD}
                  onHandleDropDown={onHandleDropDown}
                  dropdownOptions={yesNoOptions}
                  onSelectDropDownOption={onSelectDropDownOption}
                  dropdownValue={isBackoutDates == null ? "" : isBackoutDates}
                  dropDownFor="Set Blackout Stay Dates"
                  dropDownRef={isBackoutDatesDDRef}
                  // isUpdated={updateFieldObj.hotelCategory}
                />

                {checkNullUndefined(isBackoutDates) &&
                  isBackoutDates.value == 1 && (
                    <div style={{ marginBottom: "1.5rem" }}>
                      <p className="hl_feilds_lable_cls">Select Date *</p>
                      <div className="count_selected_backout_days_box">
                        <p className="p_text p_text_sm p_text_bold">
                          {`${
                            checkNullUndefined(selectedBackOutDays)
                              ? selectedBackOutDays.length
                              : 0
                          } Days Selected`}
                        </p>
                      </div>
                    </div>
                  )}
              </div>
              {checkNullUndefined(isBackoutDates) &&
                isBackoutDates.value == 1 && (
                  <div className="selected_backout_date_container bottomScrollbar">
                    {checkNullUndefined(selectedBackOutDays) &&
                    selectedBackOutDays.length != 0
                      ? selectedBackOutDays.map((date) => (
                          <div className="selected_backout_date_box">
                            <p
                              className="p_text roboto p_text_sm p_text_bold"
                              style={{ color: "#fff" }}
                            >
                              {moment(date).format("DD MMM YY")}
                            </p>
                            <Image
                              src={adminImages.WhiteCross}
                              onClick={() => removeDate(date)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        ))
                      : null}
                  </div>
                )}

              <HotelListingCustomDropDown
                label={"Set Refundability *"}
                placeholder="Select Option"
                dropdownStatus={refundabilityDD}
                onHandleDropDown={onHandleDropDown}
                dropdownOptions={refundabiltyOptions}
                onSelectDropDownOption={onSelectDropDownOption}
                dropdownValue={refundability == null ? "" : refundability}
                dropDownFor="Set Refundability"
                dropDownRef={refundabilityDDRef}
                // isUpdated={updateFieldObj.hotelCategory}
              />

              <HotelListingCustomDropDown
                label={"Rate Plan Applicability *"}
                placeholder="Select Option"
                dropdownStatus={ratePlanApplicabilityDD}
                onHandleDropDown={onHandleDropDown}
                dropdownOptions={applicabilityOptions}
                onSelectDropDownOption={onSelectDropDownOption}
                dropdownValue={
                  ratePlanApplicability == null ? "" : ratePlanApplicability
                }
                dropDownFor="Rate Plan Applicability"
                dropDownRef={ratePlanApplicabilityDDRef}
                // isUpdated={updateFieldObj.hotelCategory}
              />

              {checkNullUndefined(ratePlanApplicability) &&
                ratePlanApplicability.value == "Selected Rate Plans" &&
                (checkNullUndefined(allRoomRatePlan) ? (
                  <div className="cp_grey_box_rate_plan bottomScrollbar">
                    {Object.keys(allRoomRatePlan).map((key) => (
                      <div className="rate-plan-outer-container">
                        <div
                          // onClick={() =>
                          //   props.handleAmenityClick(
                          //     props.category,
                          //     item.name,
                          //     item.is_selected ? 0 : 1
                          //   )
                          // }
                          className={
                            checkNullUndefined(selectedRoomRatePlan) &&
                            selectedRoomRatePlan.hasOwnProperty(key) &&
                            selectedRoomRatePlan[key].length ==
                              allRoomRatePlan[key].length
                              ? "rate_checked_checkbox"
                              : "rate_empty_check_box"
                          }
                        >
                          {checkNullUndefined(selectedRoomRatePlan) &&
                          selectedRoomRatePlan.hasOwnProperty(key) &&
                          selectedRoomRatePlan[key].length ==
                            allRoomRatePlan[key].length ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="amenity_filled_check_box"
                              viewBox="0 0 20 15"
                              fill="none"
                            >
                              <path
                                d="M1 9L6 13L18 2"
                                stroke="white"
                                strokeWidth="3"
                              />
                            </svg>
                          ) : null}
                        </div>

                        <div className="rate-room-container">
                          <p className="p_text p_text_sm p_text_bold">{key}</p>
                          <div className="rates-list-container">
                            {allRoomRatePlan[key].map((rate) => (
                              <div className="rates-div">
                                <div
                                  // onClick={() =>
                                  //   props.handleAmenityClick(
                                  //     props.category,
                                  //     item.name,
                                  //     item.is_selected ? 0 : 1
                                  //   )
                                  // }
                                  className={
                                    selectedRoomRatePlan.hasOwnProperty(key) &&
                                    selectedRoomRatePlan[key].includes(rate)
                                      ? "rate_checked_checkbox"
                                      : "rate_empty_check_box"
                                  }
                                >
                                  {selectedRoomRatePlan.hasOwnProperty(key) &&
                                  selectedRoomRatePlan[key].includes(rate) ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="amenity_filled_check_box"
                                      viewBox="0 0 20 15"
                                      fill="none"
                                    >
                                      <path
                                        d="M1 9L6 13L18 2"
                                        stroke="white"
                                        strokeWidth="3"
                                      />
                                    </svg>
                                  ) : null}
                                </div>
                                <p
                                  className="p_text roboto p_text_sm p_text_regular"
                                  style={{ color: "#818181" }}
                                >
                                  {rate}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="cp_grey_box rate_plan"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <p
                      className="p_text p_text_lg p_text_bold"
                      style={{ color: "#818181" }}
                    >
                      No Rate Plan listed
                    </p>
                  </div>
                ))}
            </div>

            {/* Step 7:  Promotion Expiry Date */}
            <div className="cp_module_container">
              <p className="custom-promotion-module-heading">
                Step 7: Promotion Expiry Date
              </p>

              <div
                style={{
                  display: "flex",
                  gap: "0.75rem",
                  flexDirection: "column",
                }}
              >
                <div className="lease_expiry_calender">
                  <div style={{ marginBottom: "0.75rem" }}>
                    <p className="hl_feilds_lable_cls">Select Expiry Date*</p>
                  </div>
                  <DatePicker
                    selected={expiryDate == null ? "" : moment(expiryDate)}
                    placeholderText="DD/MM/YY"
                    dateFormat="ll"
                    onChange={(se, event) =>
                      onChangeExpiryDate(se, event)
                    }
                  />
                </div>
                {/* <SingleDateCustomCalendar
                  open={openExpiryDate}
                  setOpen={setOpenExpiryDate}
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalender}
                  date={expiryDate}
                  setDate={setExpiryDate}
                  type=""
                  restrictedPastDate={moment()}
                  isLoading={false}
                /> */}
              </div>
            </div>

            {/* Step 8:  Confirmation */}
            <div
              className="cp_module_container"
              style={{ border: "none", marginBottom: "6rem" }}
            >
              <p className="custom-promotion-module-heading">
                Step 8: Confirmation
              </p>

              <div>
                <p className="hl_feilds_lable_cls">Enter Name of Promotion* </p>
                <div
                  style={{
                    display: "flex",
                    gap: "1.25rem",
                    alignItems: "center",
                  }}
                >
                  <CustomInputField
                    value={promotionName}
                    className={"promotion-name-field"}
                    handleInputChange={handleInputChange}
                    placeholder={"Enter Promotion Name"}
                    label={"Enter Name of Promotion *"}
                    inputFor={"Promotion Name"}
                  />
                  <button className="cp_create-promotion-btn">
                    <p
                      className="p_text p_text_sm p_text_bold"
                      style={{ color: "#fff", cursor: "pointer" }}
                    >
                      Create Promotion
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
