import React from "react";
import { Menu, Icon } from "semantic-ui-react";

const MenuItemMobile = props => {
  const renderMenuItem = () => {
    return props.menuList.map(item => (

      <Menu.Item
      key={item.pathValue}
        name={item.pathValue}
        active={props.activeItem === item.pathValue}
        value={item.icon}
        label={item.label}
        onClick={props.handleItemClick}
        style={{
          color: "#4B3274",
          padding: "1em",
          backgroundColor: null
        }}
      >
        <Icon name={item.icon} />
        {item.pathValue == "/user-permissions"
          ?
          "User Permissions"
          :
          item.isHotelClosed == 1
            ?
            "Unlisted/Closed Hotels"
            :
            item.type == "Heading" ? <p style={{ fontSize: "1.1em", fontWeight: "bold", textAlign: "center" }}>{item.label}</p> : item.label}
      </Menu.Item>

    ));
  };

  return <div style={{ marginTop: "2.5em" }}>{renderMenuItem()}</div>;
};

export default MenuItemMobile;
