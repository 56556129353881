import { TYPE } from "../actions/types";

const initialState = {
  hotelRefundPolicies: {},
  refundPolicies: {},
  updateRefundPolicyRes: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPE.GET_REFUND_POLICIES:
      state.refundPolicies = action.payload;
      return { ...state, response: action.payload };
    case TYPE.GET_HOTEL_REFUND_POLICIES.POST:
      state.hotelRefundPolicies = action.payload;
      return { ...state, response: action.payload };
    case TYPE.UPDATE_BULK_HOTEL_REFUND_POLICY.POST:
      state.updateRefundPolicyRes = action.payload;
      return { ...state, response: action.payload };
    case "clear_update_refund_policy_res":
      state.updateRefundPolicyRes = {};
      return { ...state, response: action.payload };
    case "clear_hotel_refund_policies":
      state.hotelRefundPolicies={};
          return { ...state, response: action.payload };
    case "clear_refund_policies":
            state.refundPolicies = {};
            return { ...state, response: action.payload };

    default:
      return state;
  }
}
