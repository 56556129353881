import { TYPE } from '../actions/types'

const initialState = {
    loading: true,
    ledgerPayTransactionRes:{},
    hotelSummaryRes:{},
    postLedgerResponse:{},
    ledgerResponse:{},
    getInvoiceRes:{},
    creditDebitRes:{},
    downloadRes:{},
    salesRes:{},
    cdNotesRes:{},
}

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.HOTEL_LIST_FINANCE:
            return { ...state, response: action.payload }
        case TYPE.INITIAL_ENTRY:
            return { ...state, response: action.payload }
        case TYPE.GET_INVOICES:
            state.getInvoiceRes=action.payload
            return { ...state, response: action.payload }
        case TYPE.LEDGER:
            state.ledgerResponse=action.payload
            return { ...state, response: action.payload }
        case TYPE.CREDIT_DEBIT_NOTE:
            state.creditDebitRes=action.payload
            return { ...state, response: action.payload }
        case "clear_credit_debit_note":
            state.creditDebitRes={}
            return { ...state, response: action.payload }
        case TYPE.GET_DISPUTES:
            return { ...state, response: action.payload }
        case TYPE.RESOLVE_DISPUTE:
            return { ...state, response: action.payload }
        case TYPE.CREATE_DISPUTE:
            return { ...state, response: action.payload }
        case TYPE.GET_CD_NOTES:
            state.cdNotesRes = action.payload
            return { ...state, response: action.payload }
        case TYPE.DOWNLOAD:
            state.downloadRes=action.payload
            return { ...state, response: action.payload }
        case TYPE.HOTEL_FINANCE_SUMMARY:
            state.hotelSummaryRes=action.payload
            state.loading = false
            return { ...state, response: action.payload }
        case TYPE.GET_SALES_DATA:
            state.salesRes = action.payload
            return { ...state, response: action.payload }
        case TYPE.HOTEL_PAYOUT:
            return { ...state, response: action.payload }
        case TYPE.LEDGER_PAY:
            return { ...state, response: action.payload }
        case TYPE.ACTIVITY_LOGS:
            return { ...state, response: action.payload }
        case TYPE.LEDGER_PAY_TRANSACTIONS:
            state.ledgerPayTransactionRes=action.payload
            return { ...state, response: action.payload }
        case TYPE.GET_ACTIVITY_TYPE.POST:
            return { ...state, response: action.payload }
        case TYPE.CURRENT_RUNNING_BALANACE:
            return { ...state, response: action.payload }
        case "clear_download_res":
            state.downloadRes={}
            return { ...state, response: action.payload }
        case "clear_cd_notes_res":
            state.cdNotesRes = {}
            return { ...state, response: action.payload }
        case "clear_hotel_summary_res":
            state.hotelSummaryRes = {}
            return { ...state, response: action.payload }
        case "clear_ledger_pay_tran_res":
            state.ledgerPayTransactionRes={}
            return { ...state, response: action.payload }
        case "clear_invoice_res":
            state.getInvoiceRes={}
            return { ...state, response: action.payload }
        case "clear_sales_res":
            state.salesRes={}
            return { ...state, response: action.payload }
                    
        default:
            return state;
    }
}

