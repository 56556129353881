import { TYPE } from "../actions/types";
const initialState = {
  userDetailsByMobile: [],
  errMsg: "",
  status: ""
};

export default function (state = initialState, action) {

  switch (action.type) {
    case TYPE.USER_BY_MOBILE.GET:
      state.errMsg = ""
      state.status = ""
      if (action.payload.status == "SUCCESS") {
        state.userDetailsByMobile = action.payload.userData
      } else {
        state.errMsg = action.payload.msg
        state.userDetailsByMobile = []
      }
      state.status = action.payload.status
      return {
        ...state,
      };

    case "clear_user_data":
      state.userDetailsByMobile = []
      state.errMsg = ""
      state.status = ""
      return {
        ...state,
      };

    default:
      return state;
  }
}

