import { TYPE } from '../actions/types'

const initialState = {
    adminData: {},
    adminDataForModal : {},
    isFetchingAdminDetails : false,
    fetchingAdminResults: false,
    createNewAdminRes:{},
    creatingNewAdmin: false,
    updateAdminRes : {},
    updatingAdmin:false
}

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case "FETCH_ADMIN_DATA":
            state.adminData = response
            state.fetchingAdminResults = false
            return { ...state, response: action.payload }
        case "FETCH_ADMIN_DATA_AND_AUTOFILL":
            state.adminDataForModal = response
            state.isFetchingAdminDetails = false
            return {...state, response:action.payload}
        case "CREATE_ADMIN":
            state.createNewAdminRes = response
            state.creatingNewAdmin = false
            return {...state, response:action.payload}
        case "UPDATE_ADMIN":
            state.updateAdminRes = response
            state.updatingAdmin = false
            return {...state, response: action.payload}
        case "clear_admin_response":
            state.adminData = []
            state.fetchingAdminResults = false
        case "clear_add_and_update_res":
            state.adminDataForModal = {}
            state.createNewAdminRes = {}
            state.updateAdminRes = {}
            state.fetchingAdminResults = false
            state.creatingNewAdmin = false
            state.updatingAdmin = false
        default:
            return state;

    }
}

