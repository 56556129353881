import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Segment,
    Grid,
    Sidebar,
    Button,
    Input,
    Dropdown,
    Menu,
    Label,
    Icon,
    Loader,
    Checkbox,
    Modal,
    Form,
    Popup,
    Image, Breadcrumb, Divider
} from "semantic-ui-react";
import {
    USER_DATA,
    SELECTED_HOTEL_ID,
    SELECTED_HOTEL_NAME
} from "../../../constants";
import { logout } from "../../../redux/actions/user_actions";
import { clearSession } from "../../../redux/actions/session_actions";

import MenuListMobile from "./menuItem_m"
import CustomSideNavBar from "./customSideNavBar"


function CustomHeaderComponent(props) {
    const dispatch = useDispatch();
    const [sidebarVal, setSideBarVal] = useState(false)
    const [activeItem, setActiveItem] = useState("")

    const logoutUser = () => {
        let userData = JSON.parse(localStorage.getItem(USER_DATA));
        let data = {
            "login_user_id": userData.login_user_id,
            "login_user_name": userData.login_user_name,
            "login_user_type": userData.user_access_type
        }
        dispatch(logout(data));
        dispatch(clearSession());
        props.history.push("/login");
    }

    const getMenuList = () => {
        let unlistedClosedLabel = ""
        let permissions = props.session.data.permissions
        let menuList = [];
        let subMenulist = [];
        let isHotelClosed = 0
        if (permissions.uep_hotel_listing == 1 && permissions.uep_closed_hotels == 1) {
            unlistedClosedLabel = ["Unlisted/Closed Hotels"]
            isHotelClosed = 1
        }
        else if (permissions.uep_hotel_listing == 1 && permissions.uep_closed_hotels == 0) {
            unlistedClosedLabel = "Unlisted Hotels"
        }
        else if (permissions.uep_hotel_listing == 0 && permissions.uep_closed_hotels == 1) {
            unlistedClosedLabel = "Closed Hotels"
        }

        // if (permissions.uep_hotel_profile == "1") {
        //     subMenulist.push({
        //         label: "Hotel Profile",
        //         pathValue: "/profile",
        //         icon: "user",
        //         type: "admin",
        //         isAvailableForUser: true
        //     })
        // }
        if (permissions.uep_hotel_profile == "1") {
            subMenulist.push({
                label: "Hotel Profile",
                pathValue: "/profile",
                icon: "user",
                type: "admin",
                isAvailableForUser: true
            })
        }
        if (permissions.uep_marketing == "1") {
            subMenulist.push({
                label: "Coupons",//hooks
                pathValue: "/coupon",
                icon: "address card",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true
            },
            );
        }
        if (permissions.uep_marketing == "1") {
            subMenulist.push({
                label: "SEO",//hooks
                pathValue: "/seo",
                icon: "linkify",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true
            })
        }
        if (permissions.uep_marketing == "1") {

            subMenulist.push({
                label: "Campaign",//hooks
                pathValue: "/marketing-campaign",
                icon: "server",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true
            })
        }
        if (permissions.uep_analytics != "0") {
            subMenulist.push(
                {
                    label: "Analytics",//hooks
                    pathValue: "/analytics",
                    icon: "chart bar",
                    color: "#3377ab",
                    type: "admin",
                    isAvailableForUser: true
                },
            )
        }
        if (permissions.uep_s3_bucket == "1") {
            subMenulist.push(
                {
                    label: "Manage S3",//hooks
                    pathValue: "/s3",
                    icon: "bitbucket",
                    color: "#3377ab",
                    type: "admin",
                    isAvailableForUser: true
                },
            )
        }
        if (permissions.uep_admin_dashboard == "1") {
            subMenulist.push({
                label: "Brevistay Bookings",
                pathValue: "/admin-dashboard-new",
                icon: "dashboard",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true
            },

            );
        }
        if (permissions.uep_admin_dashboard == "1") {
            subMenulist.push({
                label: "Brevistay Bookings",
                pathValue: "/holida-dashboard",
                icon: "dashboard",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true
            },

            );
        }
        if (permissions.uep_a_site_user != "0") {
            subMenulist.push({
                label: "Site User", //hooks
                pathValue: "/site-user",
                icon: "object group outline",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true,
            })
        }



        if (permissions.uep_hotel_listing == "1" || permissions.uep_closed_hotels == "1") {
            subMenulist.push({
                label: unlistedClosedLabel,
                pathValue: "/unlisted-hotels",
                icon: "building",
                color: "#3377ab",
                type: "admin",
                isHotelClosed: isHotelClosed,
                isAvailableForUser: true
            });
        }
        if (permissions.uep_finance_inhouse == "1" || permissions.uep_finance == "1") {
            subMenulist.push({
                label: "Finance",
                pathValue: "/finance-new",
                icon: "money bill alternate",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true
            })
        }

        if (permissions.uep_finance_inhouse == "1" || permissions.uep_finance == "1") {
            subMenulist.push({
                label: "Platform Charges",
                pathValue: "/platform-charges",
                icon: "money bill alternate",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true
            })
        }
        if (permissions.uep_finance_inhouse == "1") {
            subMenulist.push({
                label: "Payouts",
                pathValue: "/hotel-balance-list", //check Route
                icon: "handshake",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true
            }

            );
        }

        if (permissions.uep_activity_logs == "1") {
            subMenulist.push(
                {
                    label: "Activity Logs",
                    pathValue: "/activity-logs",
                    icon: "history",
                    color: "#3377ab",
                    type: "admin",
                    isAvailableForUser: true
                }
            );
        }
        // for Activity Log

        if (permissions.uep_user_management == "1") {
            subMenulist.push({
                label: "User Permission",
                pathValue: "/user-permissions",
                icon: "users",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true
            },
            );
        }

        if (permissions.uep_system_administration == "1") {
            subMenulist.push({
                label: "System Administrator",
                pathValue: "/system-admininstrator",
                icon: "users",
                color: "#3377ab",
                type: "admin",
                isAvailableForUser: true
            },
            );
        }

        subMenulist.push({
            label: "Change Password",
            pathValue: "/password-change",
            icon: "key",
            color: "#3377ab",
            type: "admin",
            isAvailableForUser: true
        },
        );
        return subMenulist;

    };

    const handleItemClick = (e, { name, label, value, type }) => {

        if (name !== "/password-change") {
            localStorage.setItem("path_name", name);
        }

        setSideBarVal(false)
        setActiveItem(name)

        props.history.push(name);

    };

    const closeSideNavBar = () => {
        setSideBarVal(false)
    }

    const showSideNavBar = () => {
        setSideBarVal(true)
    }


    return (
        <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", height: '100%', }}>
            <svg style={{ cursor: "pointer", }} onClick={() => showSideNavBar()} className="side_bars_icon" viewBox="0 0 49 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 20H45.6" stroke="#303030" strokeWidth="6.4" strokeLinecap="round" />
                <path d="M29.5996 36L45.5996 36" stroke="#303030" strokeWidth="6.4" strokeLinecap="round" />
                <path d="M4 4L20 4" stroke="#303030" strokeWidth="6.4" strokeLinecap="round" />
            </svg>

            <CustomSideNavBar {...props} sidebarVal={sidebarVal} showSideNavBar={showSideNavBar} closeSideNavBar={closeSideNavBar} />

            <div>
                <h1 className="screen_name_cls" style={{ color: "black", fontWeight: 500, fontFamily: "Montserrat" }}>
                    {props.screenName}
                </h1>
            </div>

        </div>
        // </div>
    );
}
export default CustomHeaderComponent;
